import React, {useEffect, useState} from 'react';
import {Form, Field} from "react-final-form";
import common from "common";
import {SkillsApi, Skill, CharacterCreateBody} from "client";
import {Link, useNavigate} from 'react-router-dom';
import {useDispatch} from "react-redux";
import {newCharacter} from "../redux/character/CharacterActions";

const CharacterCreatePage: React.FC = (): JSX.Element => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [skills, setSkills] = useState<Array<Skill>>([]);

	useEffect(() => {
		let mounted = true;
		(async () => {
			const response = await new SkillsApi().getAllSkills();
			if (response && mounted) {
				setSkills(response);
			}
		})();
		return () => {
			mounted = false;
		}
	}, []);

	async function onSubmit(values: CharacterCreateBody) {
		if (values.skills.length < 2 && !window.confirm(`Are you sure you want to start with ${values.skills.length}/2 skills?`)) {
			return;
		}

		await dispatch(newCharacter(values));

		navigate("/character/register/complete");
	}


	return (
		<div className="create-character-form">
			<Form
				onSubmit={onSubmit}
				initialValues={{skills: [],}}
			>
				{({handleSubmit, values, form}) => (
					<form onSubmit={handleSubmit}>
						<h2>Combat Rating</h2>

						<p>Roll the dice and then add another 8 to that number. This is your combat rating. The higher
							your number, the more powerful you are in combat.</p>

						<p><strong>Note:</strong> The 8 extra points will be added after character is created.</p>

						<div className="form-item">
							{(values.combatSkill) ? (
								<div className="form-input">
									<span>Roll Result: </span>
									<Field
										name="combatSkill"
										component="input"
										type="number"
										disabled={true}
									/>
								</div>
							) : (
								<button
									type="button"
									onClick={() => {
										const roll = common.utils.diceRoll();
										form.change("combatSkill", roll);
									}}
								>
									Roll Dice
								</button>
							)}
						</div>

						<h2>Health</h2>

						<p>Now you roll for your health. Roll the dice and add 20 to that value and that number will be
							your health.</p>

						<p><strong>Note:</strong> The 20 extra points will be added after character is created.</p>

						<div className="form-item">
							{(values.maxHealth) ? (
								<div className="form-input">
									<span>Roll Result: </span>
									<Field
										name="maxHealth"
										component="input"
										type="number"
										disabled={true}
									/>
								</div>
							) : (
								<button
									type="button"
									onClick={() => {
										const roll = common.utils.diceRoll();
										form.change("maxHealth", roll);
									}}
								>
									Roll Dice
								</button>
							)}
						</div>

						<h2>Skills</h2>

						<p>You are a jack-of-all-trades and a master of a few. Here you can choose up to two skills that
							you excel at. View the <Link to="/handbook">handbook</Link> for skill descriptions.</p>

						<div className="form-item">
							<div className="form-input">
								{skills.map((skill) =>
									(<div key={skill.id}>
										<label>
											<Field
												name="skills"
												component="input"
												type="checkbox"
												value={skill.id}
												disabled={(values.skills && values.skills.length >= 2 && !values.skills.includes(skill.id))}
											/>{" "}
											{skill.title}
										</label>
									</div>)
								)}
							</div>
						</div>


						<h2>Gold</h2>

						<p>Cash has always been king, but you have recently lost favor with lady luck. Roll the dice
							once to see how much gold you have saved up.</p>

						<div className="form-item">
							{(values.gold) ? (
								<div className="form-input">
									<span>Roll Result: </span>
									<Field
										name="gold"
										component="input"
										type="number"
										disabled={true}
									/>
								</div>
							) : (
								<button
									type="button"
									onClick={() => {
										const roll = common.utils.diceRoll();
										form.change("gold", roll);
									}}
								>
									Roll Dice
								</button>
							)}
						</div>

						<h2>Items</h2>

						<p>You are also in possession of the family heirloom. Your ancestors have been here a long time
							and an object has been passed down through generations. To find out what that is, roll the
							dice and match the following item.</p>

						<ol className="normList">
							<li>Sword (Weapon) This adds 4 combat to your total.</li>
							<li>Helmet (Armor) This adds 2 combat to your total.</li>
							<li>Shield (Armor) This adds 3 combat to your total.</li>
							<li>Health Charm (Special) This adds 2 health points to your total.</li>
							<li>Family safe with 10 gold pieces (Gold)</li>
							<li>Crimson Orb (Special) Glowing red orb that nobody remembers where it came from or what
								it does.
							</li>
						</ol>

						<div className="form-item">
							{(values.item) ? (
								<div className="form-input">
									<span>Roll Result: </span>
									<Field
										name="item"
										component="input"
										type="number"
										disabled={true}
									/>
								</div>
							) : (
								<button
									type="button"
									onClick={() => {
										const roll = common.utils.diceRoll();
										form.change("item", roll);
									}}
								>
									Roll Dice
								</button>
							)}
						</div>


						<div className="form-action">
							<button className="form-submit">Create Character</button>
						</div>
					</form>
				)}
			</Form>

		</div>
	);
};

export default CharacterCreatePage;
