import InitialCharacterStore, {ICharacterStore} from "./InitialCharacterStore";
import {IAction} from "../index";
import cloneDeepWith from "lodash/cloneDeepWith";
import cloneDeep from "lodash/cloneDeep";

export enum characterType {
	LOGOUT = "LOGOUT",
	LOGIN = "LOGIN",
	NEW_CHARACTER = "NEW_CHARACTER",
	LOAD_CHARACTER = "LOAD_CHARACTER",
	UPDATE_CHARACTER = "UPDATE_CHARACTER",
	REHYDRATE_AUTH = "REHYDRATE_AUTH",
}

const CharacterReducer = function (store: ICharacterStore = InitialCharacterStore, action: IAction<any | characterType, any>): ICharacterStore {

	let newStore: ICharacterStore = cloneDeep(store);

	switch (action.type) {
		case characterType.LOGOUT:
			newStore = cloneDeep(InitialCharacterStore);
			break;
		case characterType.NEW_CHARACTER + "/fulfilled":
		case characterType.LOAD_CHARACTER + "/fulfilled":
			newStore.character = action.payload.character;
			newStore.characterId = action.payload.character.id;
			break;
		case characterType.UPDATE_CHARACTER:
			newStore.character = cloneDeepWith(store.character, action.payload);
			break;
		case characterType.LOGIN:
			newStore.characterId = action.payload;
			break;
		case characterType.REHYDRATE_AUTH + "/fulfilled":
			newStore.rehydratedAuth = true;
			if (action.payload?.character) {
				newStore.character = action.payload.character;
			}
			break;
		case characterType.REHYDRATE_AUTH + "/rejected":
			newStore.rehydratedAuth = false;
			break;
	}

	return newStore;
}
export default CharacterReducer;
