import React, {useCallback, useMemo} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import common from "common";
import {Page} from "client";
import {loadCharacter} from "../../redux/character/CharacterActions";
import {itemAcquire} from "../../utils/characterEvents";

interface IProps {
	page: Page;
}

const GamebookItemAcquire: React.FC<IProps> = (props: IProps): JSX.Element => {
	const {page} = props;
	const dispatch = useDispatch();
	const characterId = useSelector((state: IStore) => state.characterStore.characterId);
	const character = useSelector((state: IStore) => state.characterStore.character);

	const onItemAcquire = useCallback((itemId: string) => {
		(async () => {
			await itemAcquire(itemId, characterId);
			dispatch(loadCharacter(characterId));
		})();
	}, [dispatch, characterId]);

	const renderItems = useMemo(() => {
		const render: Array<JSX.Element> = [];

		if (!page || !page.items) return render;

		for (const item of page.items) {
			const quantity = item.quantity;
			const checkIfAcquirable = common.utils.itemCheckAcquirable(item.id, item.category, page, character);

			render.push(
				<div key={item.id} className="py-3">
					<div><strong>{item.title}</strong></div>
					<div>Quantity: {quantity}</div>
					<div>Category: {item.category.title}</div>
					<div>{item.body}</div>
					{(item.equippedProperties?.maxHealth) && (
						<div>Max Health (when held): {item.equippedProperties.maxHealth}</div>
					)}
					{(item.equippedProperties?.combatSkill) && (
						<div>Combat Skill (when held): {item.equippedProperties.combatSkill}</div>
					)}
					{(item.activatedProperties?.health) && (
						<div>Health (when used): {item.activatedProperties.health}</div>
					)}
					{(item.activatedProperties?.gold) && (
						<div>Gold (when used): {item.activatedProperties.gold}</div>
					)}
					{(checkIfAcquirable.success) ? (
						<button
							type="button"
							className="btn-theme"
							onClick={() => onItemAcquire(item.id)}
						>
							Pick Up {item.title}
						</button>
					) : (
						<div className="text-muted">{checkIfAcquirable.error}</div>
					)}
				</div>
			);

		}

		return render;
	}, [character, page]);

	if (!page || !page.items) {
		return (<React.Fragment/>);
	}

	return (
		<div className="border-top border-dark py-3">
			<h4>Items</h4>
			{renderItems}
		</div>
	);
};

export default GamebookItemAcquire;
