import React, {useEffect, useMemo, useState} from 'react';
import {
	Item,
	Page, ItemsApi
} from "client";
import MDEditor from '@uiw/react-md-editor';
import {Field, FormRenderProps} from "react-final-form";
import rehypeSanitize from "rehype-sanitize";
import Input from "../../components/form/Input";
import SingleCheckbox from "../../components/form/SingleCheckbox";
import {Col, Row} from "react-bootstrap";
import {FieldArray} from "react-final-form-arrays";
import {OnChange} from 'react-final-form-listeners';
import AdminPageFormRenderChoices from "./AdminPageFormRenderChoices";

interface IProps extends FormRenderProps {
	pages: Array<Page>;
}

const AdminPageFormRender: React.FC<IProps> = (props: IProps): JSX.Element => {
	const {values, form, handleSubmit} = props;
	const [items, setItems] = useState<Array<Item>>([]);

	useEffect(() => {
		let mounted = true;
		(async () => {
			const response = await new ItemsApi().getAllItems();
			if (response && mounted) {
				setItems(response);
			}
		})();
		return () => {
			mounted = false;
		}
	}, []);


	const renderItems = useMemo(() => {
		if (values.items) {
			return Object.keys(values.items).map((itemId, index) => {
				const item = items.find((element) => itemId === element.id);
				if (!item) return (<React.Fragment key={`item-${index}`}/>);
				return (
					<Row key={item.id} className="mb-3">
						<Col xs={3} className="d-flex align-items-center">
							{item.title} Quantity
						</Col>
						<Col xs={3}>
							<Field
								name={`items.${itemId}`}
								component={Input}
								type="number"
								min={1}
								required={true}
							/>
						</Col>
						<Col xs={3} className="d-flex align-items-end">
							<button
								className="btn btn-danger"
								onClick={() => {
									const newItemList: { [key: string]: number } = {...values.items};
									delete newItemList[itemId];
									form.change("items", newItemList);
								}}
							>
								Remove
							</button>
						</Col>
					</Row>
				);
			})
		}
	}, [form, items, values.items]);

	return (
		<form onSubmit={handleSubmit}>
			{/*{JSON.stringify(values)}*/}
			<OnChange name="newItemId">
				{(value) => {
					if (value) {
						const newItemList: { [key: string]: number } = {...values.items};
						newItemList[value] = 1;
						form.change("items", newItemList);
						form.change("newItemId", "");
					}
				}}
			</OnChange>

			<div className="mb-3">
				<Field
					label="Page Title"
					name="title"
					component={Input}
					type="text"
				/>
			</div>

			<div className="mb-3">
				<MDEditor
					height={500}
					value={values.body}
					onChange={(value) => form.change("body", value)}
					textareaProps={{spellCheck: true}}
					previewOptions={{
						rehypePlugins: [[rehypeSanitize]],
					}}
				/>
			</div>

			<Row className="mb-3">
				<Col xs={1}>
					<Field
						label="Page #"
						name="pageNum"
						component={Input}
						type="number"
					/>
				</Col>
				<Col xs={3}>
					<Field
						label="Alter Combat Skill"
						name="combatSkill"
						component={Input}
						type="number"
					/>
					<p className="small">Events on this page temporarily increase / decrease player's combat skill.</p>
				</Col>
				<Col xs={4}>
					<Field
						label="Health"
						name="health"
						component={Input}
						type="number"
					/>
					<p className="small">Landing on this page immediately effects character health.
						Positive numbers heal and negative numbers damage.</p>
				</Col>
				<Col xs={4}>
					<Field
						label="Gold"
						name="gold"
						component={Input}
						type="number"
					/>
					<p className="small">Landing on this page immediately effects gold amount.
						Positive numbers receive and negative numbers remove.</p>
				</Col>
			</Row>
			<Row className="mb-3">
				<Col xs={4}>
					<Field
						label="This page is instant death."
						name="death"
						component={SingleCheckbox}
						type="checkbox"
					/>
				</Col>
				<Col xs={4}>
					<Field
						label="Choices on this page require a RNG dice roll."
						name="diceRoll"
						component={SingleCheckbox}
						type="checkbox"
					/>
				</Col>
				<Col xs={4}>
					<Field
						label="Landing on this page removes all items."
						name="dropAll"
						component={SingleCheckbox}
						type="checkbox"
					/>
				</Col>
			</Row>

			<h4>Enemies</h4>
			<FieldArray name="enemies">
				{({fields}) => (
					<div className="mb-3">
						{fields.map((name, index) => (
							<Row key={name} className="mb-3">
								<Col xs={3}>
									<Field
										label="Name"
										name={`${name}.name`}
										component={Input}
										type="text"
										required={true}
									/>
								</Col>
								<Col xs={3}>
									<Field
										label="Combat Skill"
										name={`${name}.combatSkill`}
										component={Input}
										type="number"
										required={true}
									/>
								</Col>
								<Col xs={3}>
									<Field
										label="Health"
										name={`${name}.health`}
										component={Input}
										type="number"
										required={true}
									/>
								</Col>
								<Col xs={3} className="d-flex align-items-end">
									<button
										className="btn btn-danger w-100"
										type="button"
										onClick={() => fields.remove(index)}
									>
										Remove
									</button>
								</Col>
							</Row>
						))}
						<button
							type="button"
							className="btn btn-primary"
							onClick={() => fields.push({name: ''})}
						>
							Add Another Enemy
						</button>
					</div>
				)}
			</FieldArray>
			<h4>Items to pick up</h4>
			<div className="mb-3">
				{renderItems}
				<Row>
					<Col xs={3}>

						<Field
							className="form-control bg-primary text-white"
							name="newItemId"
							component="select"
						>
							<option value="">Add another item</option>
							{items.sort((a, b) => a.title.localeCompare(b.title)).map((item) => (
								<option key={item.id} value={item.id}>{item.title}</option>
							))}
						</Field>
					</Col>
				</Row>

			</div>

			<AdminPageFormRenderChoices {...props} items={items}/>


			<div className="text-end">
				<button className="btn-theme">
					Submit
				</button>
			</div>


		</form>
	);
};

export default AdminPageFormRender;
