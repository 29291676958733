import React, {useMemo} from 'react';
import {useLocation} from "react-router-dom";

const TopContent: React.FC = (): JSX.Element => {
	const location = useLocation();

	const pageTitle: string = useMemo(() => {
		if (location.pathname === "/") return "A Free, Online, Interactive Gamebook";
		if (location.pathname === "/character/register") return "Create New Character";
		if (location.pathname === "/handbook") return "Handbook";
		if (location.pathname.includes("/gamebook/")) return `Page ${location.pathname.replace("/gamebook/", "")}`;
		return "";
	}, [location.pathname]);

	return (
		<div className="top-content">
			<h1 className="page-title">{pageTitle}</h1>
		</div>
	);
};

export default TopContent;
