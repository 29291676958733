"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Gamebooks API
 * A simple Express Library API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: richardhung111@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ItemWithQuantityAllOfToJSON = exports.ItemWithQuantityAllOfFromJSONTyped = exports.ItemWithQuantityAllOfFromJSON = void 0;
function ItemWithQuantityAllOfFromJSON(json) {
    return ItemWithQuantityAllOfFromJSONTyped(json, false);
}
exports.ItemWithQuantityAllOfFromJSON = ItemWithQuantityAllOfFromJSON;
function ItemWithQuantityAllOfFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'quantity': json['quantity'],
    };
}
exports.ItemWithQuantityAllOfFromJSONTyped = ItemWithQuantityAllOfFromJSONTyped;
function ItemWithQuantityAllOfToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'quantity': value.quantity,
    };
}
exports.ItemWithQuantityAllOfToJSON = ItemWithQuantityAllOfToJSON;
