import React from 'react';
import {Link} from "react-router-dom";
import logo from "../static/images/logo.png";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "../redux/defaultStore";
import common from "common";
import {logout} from "../redux/character/CharacterActions";

const Header: React.FC = (): JSX.Element => {
	const dispatch = useDispatch();
	const characterId = useSelector((state: IStore) => state.characterStore.characterId);
	const character = useSelector((state: IStore) => state.characterStore.character);
	const latestEvent = character.events.sort(common.utils.sortDocumentsDesc)[0];

	function renderStats(): JSX.Element {
		if (characterId) {
			return (
				<div className="header__top-info">
					<div className="character-passkey">
						<strong>Passkey:</strong> {characterId}
					</div>
					<div className="d-flex justify-content-between">
						<div className="character-health">
							<strong>Health:</strong> {latestEvent && latestEvent.health}/{character && character.maxHealthCalculated}
						</div>
						<div className="character-gold">
							<strong>Gold:</strong> {character && character.gold}
						</div>
					</div>
					<div className="character-actions">
						<Link to="/character/actions">Character Sheet</Link>
						<span className="border-separator"/>
						<button className="btn-link" onClick={() => dispatch(logout())}>
							Logout
						</button>
					</div>
				</div>
			);
		} else {
			return (
				<div className="header__top-info">
					<Link to="/character/load">Load Progress</Link>
					<span className="border-separator"/>
					<Link to="/character/register">Create Character</Link>
				</div>
			);
		}

	}

	return (
		<header className="header">
			<Link to="/">
				<img src={logo} alt="twisted tales" className="header__logo"/>
			</Link>

			{renderStats()}

			<nav className="header__nav">
				<ul>
					<li><Link to="/">Home</Link></li>
					<li><Link to="/gamebook">Play The Game</Link></li>
					<li><Link to="/handbook">Hero Handbook</Link></li>
					{/*<li><Link to="/page/faqs">FAQs</Link></li>*/}
					{/*<li><Link to="/page/contact">Contact</Link></li>*/}
				</ul>
			</nav>

		</header>
	);
};

export default Header;
