import React, {useEffect, useState} from 'react';
import {Navigate, useParams} from 'react-router-dom';
import {useSelector} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {Page, PagesApi} from "client";
import common from "common";
import GamebookChoices from "./GamebookChoices";
import GamebookCombat from "./GamebookCombat";
import GamebookDiceRoll from "./GamebookDiceRoll";
import GamebookItemAcquire from "./GamebookItemAcquire";
import MDEditor from "@uiw/react-md-editor";
import rehypeSanitize from "rehype-sanitize";

const GamebookPage: React.FC = (): JSX.Element => {
	const params = useParams();
	const character = useSelector((state: IStore) => state.characterStore.character);
	const characterId = useSelector((state: IStore) => state.characterStore.characterId);
	const [page, setPage] = useState<Page>();
	const pageNum: number = (params.pageNum) ? parseInt(params.pageNum) : undefined;
	const latestEvent = character.events.sort(common.utils.sortDocumentsDesc)[0];

	useEffect(() => {
		let mounted = true;
		(async () => {
			if (latestEvent) {
				const response = await new PagesApi().getPage({
					id: latestEvent.page,
				});
				if (response && mounted) {
					setPage(response);
				}
			}
		})();
		return () => {
			mounted = false;
		}
	}, [latestEvent]);

	if (!characterId) {
		return (<Navigate to="/character/register" replace={true}/>);
	}

	if (!page) {
		return (<React.Fragment/>);
	}

	if (pageNum !== page.pageNum) {
		// Page number not set or does not match character's last page
		return (<Navigate to={`/gamebook/${page.pageNum}`} replace={true}/>);
	}

	return (
		<div>
			<div className="pb-3">
				<MDEditor.Markdown source={page.body} rehypePlugins={[[rehypeSanitize]]}/>
			</div>

			{(page.items?.length > 0) && (
				<GamebookItemAcquire page={page}/>
			)}

			{(page.enemies?.length > 0) && (
				<GamebookCombat page={page}/>
			)}

			{(page.diceRoll) && (
				<GamebookDiceRoll page={page}/>
			)}

			<GamebookChoices page={page}/>
		</div>
	);
};

export default GamebookPage;
