import {EModalCustomActionTypes} from "./modalCustomReducer";
import {IAction, store} from "../index";
import {IModalCustomStore} from "./InitialModalCustomStore";
import {reduxRedirect} from "../meta/MetaActions";

export function openModalAction(): IAction<EModalCustomActionTypes.OPEN_MODAL> {
	return {
		type: EModalCustomActionTypes.OPEN_MODAL,
	};
}

export function closeModalAction(): IAction<EModalCustomActionTypes.CLOSE_MODAL> {
	// Redirect if redirection is "primed"
	const redirectOnClose = store.getState().modalCustomStore.redirectOnClose;
	const refreshOnClose = store.getState().modalCustomStore.refreshOnClose;
	if (redirectOnClose) {
		store.dispatch(reduxRedirect(redirectOnClose));
	} else if (refreshOnClose) {
		window.location.reload();
	}
	store.dispatch(resetModalAction());

	return {
		type: EModalCustomActionTypes.CLOSE_MODAL,
	}
}

export function setModalAction(modalParams: IModalCustomStore): IAction<EModalCustomActionTypes.SET_MODAL, IModalCustomStore> {
	return {
		type: EModalCustomActionTypes.SET_MODAL,
		payload: modalParams,
	};
}

export function resetModalAction(): IAction<EModalCustomActionTypes.RESET_MODAL, IModalCustomStore> {
	return {
		type: EModalCustomActionTypes.RESET_MODAL,
	};
}
