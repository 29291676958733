import {ICombatDamage, ICombatTurn} from "./combat.utils.d";
import {ECharacterEventType, PageEnemy, CharacterEvent} from "client";
import {latestPageEventChunk} from "./latestPageEventChunk.utils";
import {sortDocumentsAsc} from "./sortDocuments.utils";

/**
 * Calculate combat damage to player and enemy.
 * @param playerCombatSkill
 * @param enemyCombatSkill
 * @param diceResult Result of dice roll * 2. Number between 2-12.
 */
export function calculateCombatDamage(playerCombatSkill: number, enemyCombatSkill: number, diceResult: number): ICombatDamage {
	return {
		diceResult,
		damageToPlayer: Math.round((12 - diceResult) * enemyCombatSkill / playerCombatSkill),
		damageToEnemy: Math.round(diceResult * playerCombatSkill / enemyCombatSkill),
	}
}

/**
 * Loop through and calculate combat rounds on current page.
 * @param playerCombatSkill
 * @param enemies
 * @param events
 */
export function combatProcessTurns(playerCombatSkill: number, enemies: Array<PageEnemy>, events: Array<CharacterEvent>): { combatVictory: boolean; combatTurns: ICombatTurn[] } {
	if (!events.length) return {combatVictory: false, combatTurns: []};

	const combatEvents: Array<CharacterEvent> = latestPageEventChunk(events)
		.filter((event) => event.type === ECharacterEventType.COMBAT_ROUND).sort(sortDocumentsAsc);
	const combatTurns: Array<ICombatTurn> = [];

	let combatVictory = false;
	let enemyIndex = 0;
	let enemyCurrent: PageEnemy = enemies[enemyIndex];

	if (!enemyCurrent) return {combatVictory: false, combatTurns: []};

	let enemyCurrentHealth = enemyCurrent.health;
	let enemyName = enemyCurrent.name;
	for (const combatTurn of combatEvents) {
		const diceResult = combatTurn.diceResult;
		const damage = calculateCombatDamage(playerCombatSkill, enemyCurrent.combatSkill, diceResult);

		enemyCurrentHealth = enemyCurrentHealth - damage.damageToEnemy;
		combatTurns.push({
			...damage,
			enemyName,
			enemyHealth: enemyCurrentHealth,
			enemyCombatSkill: enemyCurrent.combatSkill,
			playerHealth: combatTurn.health,
		})

		if (enemyCurrentHealth <= 0) {
			// Enemy is dead, check for next enemy
			enemyIndex = enemyIndex + 1;
			enemyCurrent = enemies[enemyIndex];
			if (enemyCurrent) {
				enemyCurrentHealth = enemyCurrent.health;
				enemyName = enemyCurrent.name;
			} else {
				// No more enemies. Combat over.
				combatVictory = true;
			}
		}
	}
	return {
		combatVictory,
		combatTurns,
	};
}

