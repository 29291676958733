export interface IMetaStore {
	loadingText: string;
	loading: boolean;
	redirectLocation: string;
	token: string;
}

export default {
	loading: false,
	loadingText: "Loading",
	redirectLocation: "",
	token: "",
} as IMetaStore;

