import React from 'react';
import {Link} from 'react-router-dom';

const HomePage: React.FC = (): JSX.Element => {
	return (
		<div>
			<p>You've always been a lucid dreamer.
				You crossed the great ocean in your sleep to observe people from cultures that you can't name.
				You have witnessed battles happening in unknown lands between parties that you don't recognize.</p>

			<p>You have always dismissed these dreams for an overactive imagination.
				However, you have recently been dreaming of events that seemed to be happening in your own kingdom.
				Night after night you watched, horrified but powerless, as your neighbouring villages are razed by
				bandits.</p>

			<p>A messenger comes to the village bringing a report of an invading army advancing on your village.
				You know his report before he has finished telling it to you; you've dreamed about this event.
				You realize that your dreams are real and your village is in danger.
				You offer to finish on his mission to bring help from the capital, but how will you do it?</p>

			<p>A dark wood separates your village from the capital. You can go through it or around it.
				Going through is faster, but villagers who enter sometimes don't return.
				The ones that do make it out tell stories of a beastly creature.</p>

			<p>You can choose to take the main road around the wood.
				It's safer but the route is longer and the enemy is advancing fast.
				Do you have enough time to get there and back again before your village is attacked?</p>

			<p>You choose the adventure that you wish to have and endure the consequences of those actions.
				Always remember that human nature is unpredictable and that you shouldn't judge a book by its cover.</p>

			<div><Link to="/gamebook" className="btn-theme">Play the Game</Link></div>

		</div>
	);
};

export default HomePage;
