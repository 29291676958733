import {Character} from "client";

export interface ICharacterStore {
	characterId?: string;
	character?: Character;
	rehydratedAuth: boolean;
}

export default {
	rehydratedAuth: false,
	character: {items: [], skills: [], events: [],},
} as ICharacterStore;

