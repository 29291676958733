"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Gamebooks API
 * A simple Express Library API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: richardhung111@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CharacterEventToJSON = exports.CharacterEventFromJSONTyped = exports.CharacterEventFromJSON = void 0;
var runtime_1 = require("../runtime");
var ECharacterEventType_1 = require("./ECharacterEventType");
function CharacterEventFromJSON(json) {
    return CharacterEventFromJSONTyped(json, false);
}
exports.CharacterEventFromJSON = CharacterEventFromJSON;
function CharacterEventFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'entity': !(0, runtime_1.exists)(json, 'entity') ? undefined : json['entity'],
        'character': json['character'],
        'page': json['page'],
        'diceResult': !(0, runtime_1.exists)(json, 'diceResult') ? undefined : json['diceResult'],
        'health': json['health'],
        'type': (0, ECharacterEventType_1.ECharacterEventTypeFromJSON)(json['type']),
    };
}
exports.CharacterEventFromJSONTyped = CharacterEventFromJSONTyped;
function CharacterEventToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'entity': value.entity,
        'character': value.character,
        'page': value.page,
        'diceResult': value.diceResult,
        'health': value.health,
        'type': (0, ECharacterEventType_1.ECharacterEventTypeToJSON)(value.type),
    };
}
exports.CharacterEventToJSON = CharacterEventToJSON;
