import React, {useEffect, useState} from 'react';
import {Accordion} from "react-bootstrap";
import {SkillsApi, Skill} from "client";

const HandbookPage: React.FC = (): JSX.Element => {
	const [skills, setSkills] = useState<Array<Skill>>([]);

	useEffect(() => {
		let mounted = true;
		(async () => {
			const response = await new SkillsApi().getAllSkills();
			if (response && mounted) {
				setSkills(response);
			}
		})();
		return () => {
			mounted = false;
		}
	}, []);

	return (
		<Accordion defaultActiveKey="items">
			<Accordion.Item eventKey="items">
				<Accordion.Header>Items</Accordion.Header>
				<Accordion.Body>
					<p>Items are split into four different categories: weapons, armor, consumables, and special items.
						Everything in your inventory is automatically equipped, and you can't choose to switch gear.</p>
					<p>Armor and special items are unique; you can wear a bronze helmet and an iron helmet at the same
						time, but not two bronze helmets.</p>
					<p>Item categories have a quantity limit. All items in a category are equal; platemail chest takes
						up the same space in your inventory as a feather cap.</p>
					<p>Item limits are as follows:</p>
					<ul>
						<li>Weapons: 1</li>
						<li>Armor: 3</li>
						<li>Consumables: 5</li>
						<li>Special: Unlimited</li>
					</ul>
				</Accordion.Body>
			</Accordion.Item>
			<Accordion.Item eventKey="combat">
				<Accordion.Header>Combat Rules</Accordion.Header>
				<Accordion.Body>
					<p>Two six sided dice are rolled on every combat turn. Dice rolls and calculations are all done on
						the website with a click of the mouse.</p>
					<h3>Short version</h3>
					<p>Bigger is better. A higher roll means you deal more damage and take less damage.</p>
					<h3>Long version</h3>
					<ul>
						<li>damage_to_player = (12 - roll) * enemy_combat_skill / player_combat_skill</li>
						<li>damage_to_enemy = roll * player_combat_skill / enemy_combat_skill</li>
					</ul>
					<p>That's the actual combat calculation I'm using.</p>
					<p>To calculate the damage to the player, I'm subtracting the roll from twelve, and multiplying it
						by the ratio difference of the combat skill stat.</p>
					<p>To calculate the damage to the enemy, I do the exact opposite. I'm multiplying just the roll by
						the reversed ratio difference of the combat skill stat.</p>
					<p>It means that if you roll a twelve, you don't take any damage, as zero multiplied by a million is
						still zero. It also means that if you somehow rolled a zero, you also deal zero damage to the
						enemy. However, since you can't roll a zero with two dice, the minimum roll will be two, so the
						player has an advantage in the roll.</p>
					<p>You hit each other at the same time. It's possible to kill each other.</p>
				</Accordion.Body>
			</Accordion.Item>
			<Accordion.Item eventKey="skills">
				<Accordion.Header>Skills</Accordion.Header>
				<Accordion.Body>
					<p>You can choose up to two skill when you first create your character. Some skills are more useful
						than others for your particular situation.</p>
					{skills.map((skill) => (
						<p key={skill.id}>
							<strong>{skill.title}</strong><br/>
							{skill.body}
						</p>
					))}
				</Accordion.Body>
			</Accordion.Item>
		</Accordion>
	);
};

export default HandbookPage;
