"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Gamebooks API
 * A simple Express Library API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: richardhung111@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PageChoiceAllOfToJSON = exports.PageChoiceAllOfFromJSONTyped = exports.PageChoiceAllOfFromJSON = void 0;
var runtime_1 = require("../runtime");
function PageChoiceAllOfFromJSON(json) {
    return PageChoiceAllOfFromJSONTyped(json, false);
}
exports.PageChoiceAllOfFromJSON = PageChoiceAllOfFromJSON;
function PageChoiceAllOfFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'title': json['title'],
        'pageOrigin': json['pageOrigin'],
        'pageTarget': !(0, runtime_1.exists)(json, 'pageTarget') ? undefined : json['pageTarget'],
        'pageTargetNum': !(0, runtime_1.exists)(json, 'pageTargetNum') ? undefined : json['pageTargetNum'],
        'gold': !(0, runtime_1.exists)(json, 'gold') ? undefined : json['gold'],
        'health': !(0, runtime_1.exists)(json, 'health') ? undefined : json['health'],
        'combat': !(0, runtime_1.exists)(json, 'combat') ? undefined : json['combat'],
        'skills': !(0, runtime_1.exists)(json, 'skills') ? undefined : json['skills'],
        'diceResult': !(0, runtime_1.exists)(json, 'diceResult') ? undefined : json['diceResult'],
        'hasItems': !(0, runtime_1.exists)(json, 'hasItems') ? undefined : json['hasItems'],
        'updateItems': !(0, runtime_1.exists)(json, 'updateItems') ? undefined : json['updateItems'],
    };
}
exports.PageChoiceAllOfFromJSONTyped = PageChoiceAllOfFromJSONTyped;
function PageChoiceAllOfToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'title': value.title,
        'pageOrigin': value.pageOrigin,
        'pageTarget': value.pageTarget,
        'pageTargetNum': value.pageTargetNum,
        'gold': value.gold,
        'health': value.health,
        'combat': value.combat,
        'skills': value.skills,
        'diceResult': value.diceResult,
        'hasItems': value.hasItems,
        'updateItems': value.updateItems,
    };
}
exports.PageChoiceAllOfToJSON = PageChoiceAllOfToJSON;
