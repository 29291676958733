import React from 'react';
import Modal from "react-bootstrap/Modal";
import {useDispatch, useSelector} from 'react-redux';
import {IStore} from "../redux/defaultStore";
import {closeModalAction, resetModalAction} from "../redux/modalCustom/modalCustomActions";

const ModalCustom: React.FC = () => {
	const dispatch = useDispatch();
	const modalStore = useSelector((state: IStore) => state.modalCustomStore);
	const modalShow = modalStore.modalShow;
	const modalTitle = modalStore.title;
	const modalBody = modalStore.body;
	const modalButtonLeft = modalStore.buttonLeft;
	const className = modalStore.className;

	return (
		<Modal show={modalShow} onHide={() => dispatch(closeModalAction())} centered dialogClassName={className}>
			{(modalTitle) && (
				<Modal.Header>
					<Modal.Title>{modalTitle}</Modal.Title>
				</Modal.Header>
			)}
			<Modal.Body>{modalBody}</Modal.Body>
			<Modal.Footer>
				{(modalButtonLeft) && (
					<button type="button" className="btn btn-nav-active" onClick={() => {
						// Need to reset modal. Otherwise it could still redirect or refresh.
						dispatch(resetModalAction());
						if (modalButtonLeft.callback) {
							modalButtonLeft.callback();
						}
					}}>
						{modalButtonLeft.text}
					</button>
				)}
				<button type="button" className="btn btn-cta-navy" onClick={() => dispatch(closeModalAction())}>
					Close
				</button>
			</Modal.Footer>
		</Modal>
	);
};

export default ModalCustom;
