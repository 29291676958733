"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Gamebooks API
 * A simple Express Library API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: richardhung111@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ItemActivatedToJSON = exports.ItemActivatedFromJSONTyped = exports.ItemActivatedFromJSON = void 0;
var runtime_1 = require("../runtime");
function ItemActivatedFromJSON(json) {
    return ItemActivatedFromJSONTyped(json, false);
}
exports.ItemActivatedFromJSON = ItemActivatedFromJSON;
function ItemActivatedFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'health': !(0, runtime_1.exists)(json, 'health') ? undefined : json['health'],
        'gold': !(0, runtime_1.exists)(json, 'gold') ? undefined : json['gold'],
    };
}
exports.ItemActivatedFromJSONTyped = ItemActivatedFromJSONTyped;
function ItemActivatedToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'health': value.health,
        'gold': value.gold,
    };
}
exports.ItemActivatedToJSON = ItemActivatedToJSON;
