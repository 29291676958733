import {
	AnyAction,
	Middleware,
	Reducer,
} from "redux";
import storage from 'redux-persist/lib/storage';// defaults to localStorage for web
import storageSession from "redux-persist/lib/storage/session";
import {
	persistReducer,
	persistStore,
	FLUSH,
	REHYDRATE,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER
} from "redux-persist";
import defaultStore from "./defaultStore";
import MetaReducer from "./meta/MetaReducer";
import logger from "redux-logger";
import {rehydrateAuth} from "./character/CharacterActions";
import ModalCustomReducer from "./modalCustom/modalCustomReducer";
import {Action, ThunkDispatch, configureStore} from "@reduxjs/toolkit";
import CharacterReducer from "./character/CharacterReducer";
import {useDispatch} from "react-redux";

const persistCharacterConfig = {
	key: "character",
	storage: storage,
	whiteList: ["characterId"],
};

const persistMetaConfig = {
	key: "meta",
	storage: storageSession,
	whiteList: ["token"],
};

const reducer: { [key: string]: Reducer } = {
	metaStore: persistReducer(persistMetaConfig, MetaReducer),
	modalCustomStore: ModalCustomReducer,
	characterStore: persistReducer(persistCharacterConfig, CharacterReducer),
};

let additionalMiddleware: Array<Middleware> = [];
if (process.env.REACT_APP_NODE_ENV !== "production") {
	additionalMiddleware = [logger];
}

async function persistorCallback(): Promise<void> {
	// @ts-ignore
	store.dispatch(rehydrateAuth()); // <-- Needs to run always, even for users without token to ensure rehydrateauth is set and redirects work
}

const middleware = (getDefaultMiddleware) => getDefaultMiddleware({
	serializableCheck: {
		ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
	},
}).concat(additionalMiddleware);

export const store = configureStore({
	reducer,
	middleware,
	preloadedState: defaultStore,
});
export const persistor = persistStore(store, {}, persistorCallback);

export interface IAction<T = any, P = undefined> extends AnyAction {
	type: T;
	payload?: P;
}

export type RootState = ReturnType<typeof store.getState>;
export type ThunkAppDispatch = ThunkDispatch<RootState, void, Action>;

export const useAppThunkDispatch = () => useDispatch<ThunkAppDispatch>();

