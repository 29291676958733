import {TimeStamps} from "client";

export function sortDocumentsAsc(a: TimeStamps, b: TimeStamps): number {
	if (a.createdAt > b.createdAt) return 1;
	if (a.createdAt < b.createdAt) return -1;
	return 0;
}

export function sortDocumentsDesc(a: TimeStamps, b: TimeStamps): number {
	if (a.createdAt > b.createdAt) return -1;
	if (a.createdAt < b.createdAt) return 1;
	return 0;
}

