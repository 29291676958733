import React, {useEffect, useMemo, useState} from 'react';
import ReactFlow, {Node, Position, Controls, ConnectionLineType} from "react-flow-renderer";
import dagre from "dagre";
import {useSelector} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {Edge} from "react-flow-renderer/dist/esm/types";
import {AdminApi, Page, PageChoice} from "client";
import getConfig from "../../utils/getConfig";

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const nodeWidth = 172;
const nodeHeight = 36;

/**
 * https://reactflow.dev/examples/layouting/
 * @param nodes
 * @param edges
 * @param direction
 */
function getLayoutedElements(nodes: Array<Node>, edges: Array<Edge>, direction: "TB" | "LR" = "TB"): { nodes: Node[], edges: Edge[] } {
	const isHorizontal = direction === 'LR';
	dagreGraph.setGraph({rankdir: direction});

	nodes.forEach((node) => {
		dagreGraph.setNode(node.id, {width: nodeWidth, height: nodeHeight});
	});

	edges.forEach((edge) => {
		dagreGraph.setEdge(edge.source, edge.target);
	});

	dagre.layout(dagreGraph);

	nodes.forEach((node) => {
		const nodeWithPosition = dagreGraph.node(node.id);
		node.targetPosition = isHorizontal ? Position.Left : Position.Top;
		node.sourcePosition = isHorizontal ? Position.Right : Position.Bottom;

		// We are shifting the dagre node position (anchor=center center) to the top left
		// so it matches the React Flow node anchor point (top left).
		node.position = {
			x: nodeWithPosition.x - nodeWidth / 2,
			y: nodeWithPosition.y - nodeHeight / 2,
		};

		return node;
	});

	return {nodes, edges};
}

const AdminFlow: React.FC = (): JSX.Element => {
	const token = useSelector((state: IStore) => state.metaStore.token);
	const [pages, setPages] = useState<Array<Page>>([]);
	const [pageChoices, setPageChoices] = useState<Array<PageChoice>>([]);

	useEffect(() => {
		let mounted = true;
		(async () => {
			if (token) {
				const response = await new AdminApi(getConfig(token)).adminPages();
				if (mounted) {
					setPages(response);
				}
			}
		})();
		(async () => {
			if (token) {
				const response = await new AdminApi(getConfig(token)).adminPageChoices();
				if (mounted) {
					setPageChoices(response);
				}
			}
		})();
		return () => {
			mounted = false;
		}
	}, [token]);

	const nodes: Array<Node> = useMemo(() => {
		const newNodes: Array<Node> = [];

		pages.forEach((page) => {
			const pageCurrentChoices: PageChoice = pageChoices.find((element) => element.pageOrigin === page?.id);
			let type: string = "default";
			if (page.pageNum === 1) type = "input";
			if (!pageCurrentChoices) type = "output";

			newNodes.push({
				id: page.id,
				data: {label: `Page ${page.pageNum} ${page.title}`},
				position: {x: 0, y: 0},
				type: type,
			});
		});

		return newNodes;
	}, [pageChoices, pages]);

	const edges: Array<Edge> = useMemo(() => {
		const newEdges: Array<Edge> = [];

		pageChoices.forEach((choice) => {
			newEdges.push({
				id: choice.id,
				label: choice.title,
				// position: {x: 0, y: 0},
				source: choice.pageOrigin,
				target: choice.pageTarget,
				animated: true,
				type: ConnectionLineType.SmoothStep,
			});
		});

		return newEdges;
	}, [pageChoices]);

	const layoutElements = getLayoutedElements(nodes, edges);

	return (
		<div className="admin-flow">
			<ReactFlow
				nodes={layoutElements.nodes}
				edges={layoutElements.edges}
				fitView
			>
				<Controls/>
			</ReactFlow>
		</div>
	);
};

export default AdminFlow;
