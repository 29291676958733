import {CharacterEventsApi, ECharacterEventType} from 'client';

export function itemDrop(itemId: string, characterId: string) {
	return new CharacterEventsApi().createCharacterEvent({
		characterEventCreateBody: {
			entity: itemId,
			character: characterId,
			type: ECharacterEventType.ITEM_DROP,
		},
	})
}

export function nextPage(choiceId: string, characterId: string) {
	return new CharacterEventsApi().createCharacterEvent({
		characterEventCreateBody: {
			entity: choiceId,
			character: characterId,
			type: ECharacterEventType.PAGE_NEXT,
		},
	})
}

export function combatRound(characterId: string) {
	return new CharacterEventsApi().createCharacterEvent({
		characterEventCreateBody: {
			character: characterId,
			type: ECharacterEventType.COMBAT_ROUND,
		},
	})
}

/**
 * Page content has RNG. Roll dice.
 * @param characterId
 */
export function pageDiceRoll(characterId: string) {
	return new CharacterEventsApi().createCharacterEvent({
		characterEventCreateBody: {
			character: characterId,
			type: ECharacterEventType.DICE_ROLL,
		},
	})
}


export function itemAcquire(itemId: string, characterId: string) {
	return new CharacterEventsApi().createCharacterEvent({
		characterEventCreateBody: {
			character: characterId,
			entity: itemId,
			type: ECharacterEventType.ITEM_ACQUIRE,
		},
	})
}


export function itemUse(itemId: string, characterId: string) {
	return new CharacterEventsApi().createCharacterEvent({
		characterEventCreateBody: {
			character: characterId,
			entity: itemId,
			type: ECharacterEventType.ITEM_USE,
		},
	})
}

