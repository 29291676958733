"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Gamebooks API
 * A simple Express Library API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: richardhung111@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ECharacterEventTypeToJSON = exports.ECharacterEventTypeFromJSONTyped = exports.ECharacterEventTypeFromJSON = exports.ECharacterEventType = void 0;
/**
 *
 * @export
 */
exports.ECharacterEventType = {
    CHARACTER_CREATION: 'CHARACTER_CREATION',
    PAGE_PREPROCESS: 'PAGE_PREPROCESS',
    CHOICE_PREPROCESS: 'CHOICE_PREPROCESS',
    PAGE_NEXT: 'PAGE_NEXT',
    ITEM_ACQUIRE: 'ITEM_ACQUIRE',
    ITEM_DROP: 'ITEM_DROP',
    ITEM_USE: 'ITEM_USE',
    COMBAT_ROUND: 'COMBAT_ROUND',
    DICE_ROLL: 'DICE_ROLL'
};
function ECharacterEventTypeFromJSON(json) {
    return ECharacterEventTypeFromJSONTyped(json, false);
}
exports.ECharacterEventTypeFromJSON = ECharacterEventTypeFromJSON;
function ECharacterEventTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.ECharacterEventTypeFromJSONTyped = ECharacterEventTypeFromJSONTyped;
function ECharacterEventTypeToJSON(value) {
    return value;
}
exports.ECharacterEventTypeToJSON = ECharacterEventTypeToJSON;
