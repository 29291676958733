"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Gamebooks API
 * A simple Express Library API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: richardhung111@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ItemCategoryAllOfToJSON = exports.ItemCategoryAllOfFromJSONTyped = exports.ItemCategoryAllOfFromJSON = void 0;
var runtime_1 = require("../runtime");
function ItemCategoryAllOfFromJSON(json) {
    return ItemCategoryAllOfFromJSONTyped(json, false);
}
exports.ItemCategoryAllOfFromJSON = ItemCategoryAllOfFromJSON;
function ItemCategoryAllOfFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'title': json['title'],
        'body': !(0, runtime_1.exists)(json, 'body') ? undefined : json['body'],
        'unique': !(0, runtime_1.exists)(json, 'unique') ? undefined : json['unique'],
        'consumable': !(0, runtime_1.exists)(json, 'consumable') ? undefined : json['consumable'],
        'limit': !(0, runtime_1.exists)(json, 'limit') ? undefined : json['limit'],
    };
}
exports.ItemCategoryAllOfFromJSONTyped = ItemCategoryAllOfFromJSONTyped;
function ItemCategoryAllOfToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'title': value.title,
        'body': value.body,
        'unique': value.unique,
        'consumable': value.consumable,
        'limit': value.limit,
    };
}
exports.ItemCategoryAllOfToJSON = ItemCategoryAllOfToJSON;
