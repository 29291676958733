import React, {useEffect, useMemo, useState} from "react";
import {Link} from "react-router-dom";
import {ListGroup} from "react-bootstrap";
import {useSelector} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {useAppThunkDispatch} from "../../redux";
import {authorizationClear} from "../../redux/meta/MetaActions";
import {AdminApi, Page} from "client";
import getConfig from "../../utils/getConfig";

const Admin: React.FC = (): JSX.Element => {
	const token = useSelector((state: IStore) => state.metaStore.token);
	const dispatch = useAppThunkDispatch();
	const [pages, setPages] = useState<Array<Page>>([]);

	useEffect(() => {
		let mounted = true;
		(async () => {
			if (token) {
				const response = await new AdminApi(getConfig(token)).adminPages();
				if (mounted) {
					setPages(response);
				}
			}
		})();
		return () => {
			mounted = false;
		}
	}, [token]);

	const renderPages = useMemo(() => {
		return pages.sort((a, b) => a.pageNum - b.pageNum).map((page) => {
			return (
				<ListGroup.Item
					key={page.id}
					title={page.body}
					className="d-flex justify-content-between align-items-center"
				>
					Page {page.pageNum} {page.title} {(page.death) && "(Death)"}
					<Link to={`/admin/page/${page.id}`}>Edit</Link>
				</ListGroup.Item>
			);
		});
	}, [pages]);

	if (!token) {
		return (
			<div className="mb-3">
				<Link to="/admin/auth" className="btn btn-theme me-3">Auth Access</Link>
			</div>
		);
	}

	return (
		<div>
			<div className="mb-3">
				<Link to="/admin/page" className="btn btn-theme me-3">Create New Page</Link>
				<Link to="/admin/flow" className="btn btn-theme me-3">Page Flow</Link>
				<button className="btn btn-theme me-3" onClick={() => dispatch(authorizationClear())}>Logout</button>
			</div>
			<ListGroup>
				{renderPages}
			</ListGroup>
		</div>
	);
};

export default Admin;
