import React from 'react';
import {Field, Form} from "react-final-form";
import {useAppThunkDispatch} from "../../redux";
import {authorizationLoad} from "../../redux/meta/MetaActions";
import {metaType} from "../../redux/meta/MetaReducer";
import {useNavigate} from "react-router-dom";

const AdminAuthForm: React.FC = (): JSX.Element => {
	const dispatch = useAppThunkDispatch();
	const navigate = useNavigate();

	async function onSubmit(values: { password: string }): Promise<void> {
		const response = await dispatch(authorizationLoad(values.password));
		if (response.type === metaType.AUTHORIZATION_LOAD + "/fulfilled") {
			navigate("/admin");
		} else {
			alert("Admin access request failed.");
		}
	}

	return (
		<Form
			onSubmit={onSubmit}
		>
			{({handleSubmit}) => (
				<form onSubmit={handleSubmit}>
					<h2>Admin Auth</h2>

					<div className="form-item">
						<div className="form-input">
							<span>Password: </span>
							<Field
								name="password"
								component="input"
								type="password"
								required={true}
							/>
						</div>
					</div>
					<div className="form-action">
						<button className="form-submit">Submit</button>
					</div>

				</form>

			)}
		</Form>

	);
};

export default AdminAuthForm;
