import React from 'react';
import {Form, Field} from "react-final-form";
import {loadCharacter} from "../redux/character/CharacterActions";
import {characterType} from "../redux/character/CharacterReducer";
import {SubmissionErrors} from "final-form";
import {useAppThunkDispatch} from "../redux";
import Input from "../components/form/Input";
import {useNavigate} from "react-router-dom";

const CharacterLoadPage: React.FC = (): JSX.Element => {
	const navigate = useNavigate();
	const dispatch = useAppThunkDispatch();

	async function onSubmit(values: { token: string }): Promise<void | SubmissionErrors> {
		const character = await dispatch(loadCharacter(values.token));
		if (character.type === characterType.LOAD_CHARACTER + "/fulfilled") {
			navigate("/character/load/complete");
		} else {
			return {
				token: "Passkey is invalid."
			}
		}
	}


	return (
		<div className="load-character-form">
			<Form
				onSubmit={onSubmit}
			>
				{({handleSubmit}) => (
					<form onSubmit={handleSubmit}>
						<h2>Load Character</h2>

						<div className="mb-3">
							<Field
								label="Passkey"
								name="token"
								type="text"
								required={true}
								component={Input}
							/>
						</div>

						<div className="form-action">
							<button className="form-submit">Load Character</button>
						</div>
					</form>
				)}
			</Form>

		</div>
	);
};

export default CharacterLoadPage;
