import InitialMetaStore, {IMetaStore} from "./InitialMetaStore";
import {IAction} from "../index";
import cloneDeep from "lodash/cloneDeep";

export enum metaType {
	LOADING = "LOADING",
	REDIRECT = "REDIRECT",
	REDIRECT_RESET = "REDIRECT_RESET",
	AUTHORIZATION_LOAD = "AUTHORIZATION_LOAD",
	AUTHORIZATION_CLEAR = "AUTHORIZATION_CLEAR",
}

const MetaReducer = function (store: IMetaStore = InitialMetaStore, action: IAction<any | metaType, any>): IMetaStore {

	const newStore: IMetaStore = cloneDeep(store);

	switch (action.type) {
		case metaType.LOADING:
			newStore.loading = action.payload as boolean;
			break;
		case metaType.AUTHORIZATION_LOAD + "/fulfilled":
			newStore.token = action.payload as string;
			break;
		case metaType.AUTHORIZATION_CLEAR:
			newStore.token = "";
			break;
		case metaType.REDIRECT:
		case metaType.REDIRECT_RESET:
			newStore.redirectLocation = action.payload;
			break;
		default:
			break;
	}

	return newStore;
}
export default MetaReducer;
