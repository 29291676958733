"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Gamebooks API
 * A simple Express Library API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: richardhung111@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CharacterCreateBodyToJSON = exports.CharacterCreateBodyFromJSONTyped = exports.CharacterCreateBodyFromJSON = void 0;
var runtime_1 = require("../runtime");
function CharacterCreateBodyFromJSON(json) {
    return CharacterCreateBodyFromJSONTyped(json, false);
}
exports.CharacterCreateBodyFromJSON = CharacterCreateBodyFromJSON;
function CharacterCreateBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'gold': !(0, runtime_1.exists)(json, 'gold') ? undefined : json['gold'],
        'item': !(0, runtime_1.exists)(json, 'item') ? undefined : json['item'],
        'maxHealth': !(0, runtime_1.exists)(json, 'maxHealth') ? undefined : json['maxHealth'],
        'skills': !(0, runtime_1.exists)(json, 'skills') ? undefined : json['skills'],
        'combatSkill': !(0, runtime_1.exists)(json, 'combatSkill') ? undefined : json['combatSkill'],
    };
}
exports.CharacterCreateBodyFromJSONTyped = CharacterCreateBodyFromJSONTyped;
function CharacterCreateBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'gold': value.gold,
        'item': value.item,
        'maxHealth': value.maxHealth,
        'skills': value.skills,
        'combatSkill': value.combatSkill,
    };
}
exports.CharacterCreateBodyToJSON = CharacterCreateBodyToJSON;
