import {metaType} from "./MetaReducer";
import {IAction} from "../index";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {AdminApi} from "client";

export function loading(show: boolean): IAction<metaType.LOADING, boolean> {
	return {
		type: metaType.LOADING,
		payload: show,
	}
}

export function reduxRedirect(redirectLocation: string): IAction<metaType.REDIRECT, string> {
	return {
		type: metaType.REDIRECT,
		payload: redirectLocation,
	};
}

export function reduxRedirectReset(): IAction<metaType.REDIRECT_RESET, string> {
	return {
		type: metaType.REDIRECT_RESET,
		payload: "",
	};
}

export function authorizationClear(): IAction<metaType.AUTHORIZATION_CLEAR, string> {
	return {
		type: metaType.AUTHORIZATION_CLEAR,
	};
}

export const authorizationLoad = createAsyncThunk(metaType.AUTHORIZATION_LOAD, async (password: string, {rejectWithValue}) => {
	const response = await new AdminApi().adminAuth({
		adminAuthRequest: {password},
	});
	if (response) {
		return response;
	} else {
		return rejectWithValue("Authorization Failed");
	}
});
