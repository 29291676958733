import {IUtils} from "./index.d";
import diceRoll from "./diceRoll.utils";
import {sortDocumentsAsc, sortDocumentsDesc} from "./sortDocuments.utils";
import {pageChoiceValidation} from "./pageChoice.utils";
import {calculateCombatDamage, combatProcessTurns} from "./combat.utils";
import {latestPageEventChunk} from "./latestPageEventChunk.utils";
import {itemCheckAcquirable, objectItemQuantity} from "./item.utils";

const utils: IUtils = {
	diceRoll,
	sortDocumentsAsc,
	sortDocumentsDesc,
	pageChoiceValidation,
	latestPageEventChunk,
	calculateCombatDamage,
	combatProcessTurns,
	itemCheckAcquirable,
	objectItemQuantity,
}

export default utils;
