import React, {useCallback, useMemo} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "../redux/defaultStore";
import common from "common";
import {loadCharacter} from "../redux/character/CharacterActions";
import {ItemCategory} from 'client';
import {itemDrop, itemUse} from "../utils/characterEvents";

const CharacterActionsPage: React.FC = (): JSX.Element => {
	const dispatch = useDispatch();
	const character = useSelector((state: IStore) => state.characterStore.character);
	const characterId = useSelector((state: IStore) => state.characterStore.characterId);
	const latestEvent = character.events.sort(common.utils.sortDocumentsDesc)[0];

	const onItemDrop = useCallback((itemId: string) => {
		itemDrop(itemId, characterId).then(() => {
			dispatch(loadCharacter(characterId));
		});
	}, [dispatch, characterId]);

	const onItemUse = useCallback((itemId: string) => {
		itemUse(itemId, characterId).then(() => {
			dispatch(loadCharacter(characterId));
		});
	}, [dispatch, characterId]);

	const renderItemCategories = useMemo(() => {
		if (!character.items) return [];
		const itemCategories: Array<ItemCategory> = [];

		character.items.forEach((item) => {
			if (!itemCategories.find((category) => category.id === item.category.id)) {
				itemCategories.push(item.category);
			}
		});

		return itemCategories.map((itemCategory) => {
			const renderItems: JSX.Element[] = [];
			const itemsInCategory = character.items.filter((element) => element.category.id === itemCategory.id);
			for (const item of itemsInCategory) {
				renderItems.push(
					<div key={item.id} className="card mb-3">
						<div className="card-body">
							<div className="card-title"><strong>{item.title}</strong></div>
							<div className="card-subtitle text-muted">
								Quantity: {item.quantity}
								<button
									className="btn-link ms-3"
									onClick={() => onItemDrop(item.id)}
								>
									Drop Item
								</button>
								{(itemCategory.consumable) && (
									<button
										className="btn-link ms-3"
										onClick={() => onItemUse(item.id)}
									>
										Use Item
									</button>
								)}
							</div>
							<div className="card-text">{item.body}</div>
							<div>
								{(item.equippedProperties?.maxHealth) && (
									<div>Max Health (when held): {item.equippedProperties.maxHealth}</div>
								)}
								{(item.equippedProperties?.combatSkill) && (
									<div>Combat Skill (when held): {item.equippedProperties.combatSkill}</div>
								)}
								{(item.activatedProperties?.health) && (
									<div>Health (when used): {item.activatedProperties.health}</div>
								)}
								{(item.activatedProperties?.gold) && (
									<div>Gold (when used): {item.activatedProperties.gold}</div>
								)}
							</div>
						</div>
					</div>
				);
			}
			if (renderItems.length) {
				return (
					<div key={itemCategory.id}>
						<h4>{itemCategory.title}</h4>
						{renderItems}
					</div>
				);
			} else {
				return null;
			}
		});
	}, [character.items]);

	return (
		<div>
			<div className="mb-3">
				<h3>Stats</h3>
				<div>Current Health: {latestEvent && latestEvent.health}</div>
				<div>Max Health (natural): {latestEvent && character.maxHealth}</div>
				<div>Max Health (with items): {latestEvent && character.maxHealthCalculated}</div>
				<div>Gold: {character && character.gold}</div>
				<div>Combat Skill (natural): {character && character.combatSkill}</div>
				<div>Combat Skill (with items): {character && character.combatSkillCalculated}</div>
			</div>
			<div className="mb-3">
				<h3>Skills</h3>
				{character.skills.map((skill) => (
					<div key={skill.id}>
						{skill.title}
					</div>
				))}
			</div>
			<div className="mb-3">
				<h3>Items</h3>
				{renderItemCategories}
			</div>
		</div>
	);
};

export default CharacterActionsPage;
