import {IAction} from "../index";
import cloneDeep from "lodash/cloneDeep";
import InitialModalCustomStore, {IModalCustomStore} from "./InitialModalCustomStore";

export enum EModalCustomActionTypes {
	OPEN_MODAL = "OPEN_MODAL",
	CLOSE_MODAL = "CLOSE_MODAL",
	SET_MODAL = "SET_MODAL",
	RESET_MODAL = "RESET_MODAL",
}

const ModalCustomReducer = function (store: IModalCustomStore = InitialModalCustomStore, action: IAction<any, any>): IModalCustomStore {

	let newStore: IModalCustomStore = cloneDeep(store);

	switch (action.type) {
		case EModalCustomActionTypes.OPEN_MODAL:
			return {...newStore, modalShow: true};
		case EModalCustomActionTypes.CLOSE_MODAL:
			return {...newStore, modalShow: false};
		case EModalCustomActionTypes.SET_MODAL:
			return {className: InitialModalCustomStore.className, ...action.payload};
		case EModalCustomActionTypes.RESET_MODAL:
			return {...InitialModalCustomStore};
	}

	return newStore;
}
export default ModalCustomReducer;
