import * as React from "react";
import {ReactNode} from "react";
import {FieldRenderProps} from "react-final-form";

interface ISingleCheckboxProps extends FieldRenderProps<boolean> {
	label: ReactNode;
	containerClasses?: string;
	labelClasses?: string;
}

/**
 * @category Render Component
 * @param props
 * @constructor
 */
const SingleCheckbox: React.FC<ISingleCheckboxProps> = (props: ISingleCheckboxProps): JSX.Element => {

	const {meta, input, label, ...rest} = props;

	return (
		<div className="component--final-form-checkbox">

			<label
				htmlFor={input.name}
				className="d-flex flex-between align-items-center"
			>
				<span className={"pe-3"}>
					<input
						type="checkbox"
						id={input.name}
						checked={input.checked}
						name={input.name}
						onChange={input.onChange}
						{...rest}
					/>
				</span>
				<span>
					{label}
				</span>
			</label>

			{(meta.touched && (meta.error || meta.submitError)) && (
				<div className="text-danger">
					{meta.error || meta.submitError}
				</div>
			)}

		</div>
	);
};

export default SingleCheckbox;
