"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Gamebooks API
 * A simple Express Library API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: richardhung111@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PageCreateBodyAllOfToJSON = exports.PageCreateBodyAllOfFromJSONTyped = exports.PageCreateBodyAllOfFromJSON = void 0;
var runtime_1 = require("../runtime");
var PageChoice_1 = require("./PageChoice");
function PageCreateBodyAllOfFromJSON(json) {
    return PageCreateBodyAllOfFromJSONTyped(json, false);
}
exports.PageCreateBodyAllOfFromJSON = PageCreateBodyAllOfFromJSON;
function PageCreateBodyAllOfFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'choices': !(0, runtime_1.exists)(json, 'choices') ? undefined : (json['choices'].map(PageChoice_1.PageChoiceFromJSON)),
        'items': !(0, runtime_1.exists)(json, 'items') ? undefined : json['items'],
    };
}
exports.PageCreateBodyAllOfFromJSONTyped = PageCreateBodyAllOfFromJSONTyped;
function PageCreateBodyAllOfToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'choices': value.choices === undefined ? undefined : (value.choices.map(PageChoice_1.PageChoiceToJSON)),
        'items': value.items,
    };
}
exports.PageCreateBodyAllOfToJSON = PageCreateBodyAllOfToJSON;
