import {ECharacterEventType, Character, Page, PageChoice} from "client";
import {combatProcessTurns} from "./combat.utils";

/**
 * Check if character passes validation for selected page choice.
 * @param choice
 * @param page
 * @param character
 */
export function pageChoiceValidation(choice: PageChoice, page: Page, character: Character): boolean {
	if (choice.combat) {
		// Check if character has won battle
		if (!combatProcessTurns(character.combatSkillCalculated, page.enemies, character.events).combatVictory) {
			return false;
		}
	}

	if (choice.hasItems) {
		// Check if character has every item a choice requires
		for (const itemId in choice.hasItems) {
			const requiredQuantity = choice.hasItems[itemId];
			const characterQuantity: number = character?.items.find(i => i.id === itemId)?.quantity;
			if (!characterQuantity || characterQuantity < requiredQuantity) return false;
		}
	}

	if (choice.skills) {
		// Check if character has every skill a choice requires
		const skillCheck: boolean = choice.skills.every((skillId) => {
			return character.skills?.find((skill) => skill.id === skillId);
		});

		// Check if character has every skill a choice requires
		if (!skillCheck) {
			return false;
		}
	}

	if (choice.gold) {
		// Check if character has minimum amount of gold
		if (character.gold + choice.gold < 0) {
			return false;
		}
	}

	if (choice.diceResult?.length) {
		const eventDiceRoll = character.events.find((event) => event.type === ECharacterEventType.DICE_ROLL);
		if (!eventDiceRoll || !choice.diceResult.includes(eventDiceRoll.diceResult)) {
			return false;
		}
	}

	// No fails. Return true.
	return true;
}
