import React from 'react';
import {Link} from "react-router-dom";

const CharacterLoadCompletePage: React.FC = (): JSX.Element => {

	return (
		<div className="">
			<p>You have successfully loaded your character.</p>

			<div><Link to="/gamebook" className="btn-theme">Play the Game</Link></div>
		</div>
	);
};

export default CharacterLoadCompletePage;
