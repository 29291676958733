"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Gamebooks API
 * A simple Express Library API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: richardhung111@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PageAllOfToJSON = exports.PageAllOfFromJSONTyped = exports.PageAllOfFromJSON = void 0;
var runtime_1 = require("../runtime");
var ItemWithQuantity_1 = require("./ItemWithQuantity");
var PageChoice_1 = require("./PageChoice");
var PageEnemy_1 = require("./PageEnemy");
function PageAllOfFromJSON(json) {
    return PageAllOfFromJSONTyped(json, false);
}
exports.PageAllOfFromJSON = PageAllOfFromJSON;
function PageAllOfFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'title': !(0, runtime_1.exists)(json, 'title') ? undefined : json['title'],
        'body': json['body'],
        'pageNum': json['pageNum'],
        'death': !(0, runtime_1.exists)(json, 'death') ? undefined : json['death'],
        'health': !(0, runtime_1.exists)(json, 'health') ? undefined : json['health'],
        'gold': !(0, runtime_1.exists)(json, 'gold') ? undefined : json['gold'],
        'diceRoll': !(0, runtime_1.exists)(json, 'diceRoll') ? undefined : json['diceRoll'],
        'dropAll': !(0, runtime_1.exists)(json, 'dropAll') ? undefined : json['dropAll'],
        'combatSkill': !(0, runtime_1.exists)(json, 'combatSkill') ? undefined : json['combatSkill'],
        'items': !(0, runtime_1.exists)(json, 'items') ? undefined : (json['items'].map(ItemWithQuantity_1.ItemWithQuantityFromJSON)),
        'choices': !(0, runtime_1.exists)(json, 'choices') ? undefined : (json['choices'].map(PageChoice_1.PageChoiceFromJSON)),
        'enemies': !(0, runtime_1.exists)(json, 'enemies') ? undefined : (json['enemies'].map(PageEnemy_1.PageEnemyFromJSON)),
    };
}
exports.PageAllOfFromJSONTyped = PageAllOfFromJSONTyped;
function PageAllOfToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'title': value.title,
        'body': value.body,
        'pageNum': value.pageNum,
        'death': value.death,
        'health': value.health,
        'gold': value.gold,
        'diceRoll': value.diceRoll,
        'dropAll': value.dropAll,
        'combatSkill': value.combatSkill,
        'items': value.items === undefined ? undefined : (value.items.map(ItemWithQuantity_1.ItemWithQuantityToJSON)),
        'choices': value.choices === undefined ? undefined : (value.choices.map(PageChoice_1.PageChoiceToJSON)),
        'enemies': value.enemies === undefined ? undefined : (value.enemies.map(PageEnemy_1.PageEnemyToJSON)),
    };
}
exports.PageAllOfToJSON = PageAllOfToJSON;
