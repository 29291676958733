import * as React from "react";
import {FieldRenderProps} from "react-final-form";

interface IInputProps extends FieldRenderProps<string> {
	label: string;
}

/**
 * @param props
 * @category Input - Component Hook
 * @constructor
 */
const Input: React.FC<IInputProps> = (props: IInputProps): JSX.Element => {

	const {meta, input, label, ...rest} = props;

	return (
		<div>
			{label && (
				<label
					htmlFor={input.name}
					className="control-label"
				>
					{label}
				</label>
			)}

			<input
				id={input.name}
				onChange={input.onChange}
				value={input.value}
				className="form-control"
				type={input.type}
				{...rest}
			/>
			{(meta.touched && (meta.error || meta.submitError)) && (
				<div className="text-danger">
					{meta.error || meta.submitError}
				</div>
			)}

		</div>
	);
};

export default Input;
