"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Gamebooks API
 * A simple Express Library API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: richardhung111@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CharacterEventCreateBodyToJSON = exports.CharacterEventCreateBodyFromJSONTyped = exports.CharacterEventCreateBodyFromJSON = void 0;
var runtime_1 = require("../runtime");
var ECharacterEventType_1 = require("./ECharacterEventType");
function CharacterEventCreateBodyFromJSON(json) {
    return CharacterEventCreateBodyFromJSONTyped(json, false);
}
exports.CharacterEventCreateBodyFromJSON = CharacterEventCreateBodyFromJSON;
function CharacterEventCreateBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'entity': !(0, runtime_1.exists)(json, 'entity') ? undefined : json['entity'],
        'character': json['character'],
        'page': !(0, runtime_1.exists)(json, 'page') ? undefined : json['page'],
        'diceResult': !(0, runtime_1.exists)(json, 'diceResult') ? undefined : json['diceResult'],
        'health': !(0, runtime_1.exists)(json, 'health') ? undefined : json['health'],
        'type': (0, ECharacterEventType_1.ECharacterEventTypeFromJSON)(json['type']),
    };
}
exports.CharacterEventCreateBodyFromJSONTyped = CharacterEventCreateBodyFromJSONTyped;
function CharacterEventCreateBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'entity': value.entity,
        'character': value.character,
        'page': value.page,
        'diceResult': value.diceResult,
        'health': value.health,
        'type': (0, ECharacterEventType_1.ECharacterEventTypeToJSON)(value.type),
    };
}
exports.CharacterEventCreateBodyToJSON = CharacterEventCreateBodyToJSON;
