import React from 'react';
import {useSelector} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {Link} from "react-router-dom";

const CharacterCreateCompletePage: React.FC = (): JSX.Element => {
	const characterId = useSelector((state: IStore) => state.characterStore.characterId);

	return (
		<div className="">
			<p>Your character has been created. Your special passkey is <strong>{characterId}</strong>.</p>
			<p>Store this key in another location in order to save your character between browser sessions.</p>

			<div><Link to="/gamebook" className="btn-theme">Play the Game</Link></div>
		</div>
	);
};

export default CharacterCreateCompletePage;
