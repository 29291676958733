"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Gamebooks API
 * A simple Express Library API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: richardhung111@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdminAuthRequestToJSON = exports.AdminAuthRequestFromJSONTyped = exports.AdminAuthRequestFromJSON = void 0;
function AdminAuthRequestFromJSON(json) {
    return AdminAuthRequestFromJSONTyped(json, false);
}
exports.AdminAuthRequestFromJSON = AdminAuthRequestFromJSON;
function AdminAuthRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'password': json['password'],
    };
}
exports.AdminAuthRequestFromJSONTyped = AdminAuthRequestFromJSONTyped;
function AdminAuthRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'password': value.password,
    };
}
exports.AdminAuthRequestToJSON = AdminAuthRequestToJSON;
