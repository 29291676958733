"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Gamebooks API
 * A simple Express Library API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: richardhung111@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DocumentToJSON = exports.DocumentFromJSONTyped = exports.DocumentFromJSON = void 0;
function DocumentFromJSON(json) {
    return DocumentFromJSONTyped(json, false);
}
exports.DocumentFromJSON = DocumentFromJSON;
function DocumentFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
    };
}
exports.DocumentFromJSONTyped = DocumentFromJSONTyped;
function DocumentToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
    };
}
exports.DocumentToJSON = DocumentToJSON;
