"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Gamebooks API
 * A simple Express Library API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: richardhung111@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ItemWithQuantityToJSON = exports.ItemWithQuantityFromJSONTyped = exports.ItemWithQuantityFromJSON = void 0;
var runtime_1 = require("../runtime");
var ItemActivated_1 = require("./ItemActivated");
var ItemCategory_1 = require("./ItemCategory");
var ItemEquipped_1 = require("./ItemEquipped");
function ItemWithQuantityFromJSON(json) {
    return ItemWithQuantityFromJSONTyped(json, false);
}
exports.ItemWithQuantityFromJSON = ItemWithQuantityFromJSON;
function ItemWithQuantityFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'title': json['title'],
        'body': !(0, runtime_1.exists)(json, 'body') ? undefined : json['body'],
        'category': !(0, runtime_1.exists)(json, 'category') ? undefined : (0, ItemCategory_1.ItemCategoryFromJSON)(json['category']),
        'image': !(0, runtime_1.exists)(json, 'image') ? undefined : json['image'],
        'equippedProperties': !(0, runtime_1.exists)(json, 'equippedProperties') ? undefined : (0, ItemEquipped_1.ItemEquippedFromJSON)(json['equippedProperties']),
        'activatedProperties': !(0, runtime_1.exists)(json, 'activatedProperties') ? undefined : (0, ItemActivated_1.ItemActivatedFromJSON)(json['activatedProperties']),
        'quantity': json['quantity'],
    };
}
exports.ItemWithQuantityFromJSONTyped = ItemWithQuantityFromJSONTyped;
function ItemWithQuantityToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'title': value.title,
        'body': value.body,
        'category': (0, ItemCategory_1.ItemCategoryToJSON)(value.category),
        'image': value.image,
        'equippedProperties': (0, ItemEquipped_1.ItemEquippedToJSON)(value.equippedProperties),
        'activatedProperties': (0, ItemActivated_1.ItemActivatedToJSON)(value.activatedProperties),
        'quantity': value.quantity,
    };
}
exports.ItemWithQuantityToJSON = ItemWithQuantityToJSON;
