import React, {useEffect, useState} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import {AdminApi, Page, PageCreateBody, ItemWithQuantity} from "client";
import {Form, FormRenderProps} from "react-final-form";
import arrayMutators from "final-form-arrays";
import AdminPageFormRender from "./AdminPageFormRender";
import {useSelector} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import getConfig from "../../utils/getConfig";

function findLowestNumberNotInArray(array: Array<number>): number {
	let number: number = 1;
	while (array.includes(number)) {
		number = number + 1;
	}
	return number;
}

function convertItemQuantityData(items: Array<ItemWithQuantity>): { [itemId: string]: number } {
	if (!items) return {};
	const newItems: { [itemId: string]: number } = {};
	items.forEach((item) => {
		newItems[item.id] = item.quantity;
	})
	return newItems;
}

const AdminPage: React.FC = (): JSX.Element => {
	const navigate = useNavigate();
	const {pageId} = useParams();
	const token = useSelector((state: IStore) => state.metaStore.token);
	const [pages, setPages] = useState<Array<Page>>([]);
	const page: Page = pages.find((element) => pageId && pageId === element.id);
	const initialValues: PageCreateBody = {
		pageNum: (pages.length && !page) ? findLowestNumberNotInArray(pages.map((page) => page.pageNum)) : 1,
		...page,
		items: convertItemQuantityData(page?.items),
	};

	useEffect(() => {
		let mounted = true;
		(async () => {
			if (token) {
				const response = await new AdminApi(getConfig(token)).adminPages();
				if (response && mounted) {
					setPages(response);
				}
			}
		})();
		return () => {
			mounted = false;
		}
	}, [token]);

	async function onSubmit(values: PageCreateBody): Promise<{ [k in keyof Page]?: string } | void> {
		const pageNumCheck = pages.find((element) => {
			// HTML inputs return string. Interface expects number. Need to convert to string and parse into to get number.
			return (element.pageNum === parseInt(values.pageNum.toString()) && element.id !== page.id);
		})
		if (pageNumCheck) {
			return {pageNum: "Page number is already in use."};
		}

		try {
			if (pageId) {
				await new AdminApi(getConfig(token)).adminPagesUpdate({
					id: pageId,
					pageCreateBody: values,
				});
			} else {
				await new AdminApi(getConfig(token)).adminPagesCreate({
					pageCreateBody: values,
				});
			}

			alert("Page Saved");
			navigate("/admin");
		} catch (e) {
			alert("Page Submission Failure");
		}

	}

	return (
		<div>
			<Form
				onSubmit={onSubmit}
				initialValues={initialValues}
				mutators={{
					// potentially other mutators could be merged here
					...arrayMutators
				}}
			>
				{(formRenderProps: FormRenderProps) => (
					<AdminPageFormRender {...formRenderProps} pages={pages}/>
				)}
			</Form>

		</div>
	);
};

export default AdminPage;
