import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {IStore} from "../redux/defaultStore";
import {reduxRedirectReset} from "../redux/meta/MetaActions";
import {useNavigate, useLocation} from "react-router-dom";

/**
 * Function component to allow redirects triggered from the Redux store.
 * @constructor
 */
const ReduxRedirect: React.FC = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();
	const redirectLocation = useSelector((state: IStore) => state.metaStore.redirectLocation);
	if (location.pathname === redirectLocation) {
		// Redirect is complete, reset to prevent recursive looping
		dispatch(reduxRedirectReset());
	} else if (redirectLocation) {
		navigate(redirectLocation);
	}

	return (<React.Fragment/>);
};

export default ReduxRedirect;
