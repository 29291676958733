import React from 'react';
import {Container} from "react-bootstrap";
import Header from './components/Header';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import TopContent from "./components/TopContent";
import HandbookPage from "./pages/HandbookPage";
import HomePage from "./pages/HomePage";
import CharacterCreatePage from "./pages/CharacterCreatePage";
import ReduxRedirect from "./components/ReduxRedirect";
import ModalCustom from "./components/ModalCustom";
import CharacterLoadCompletePage from "./pages/CharacterLoadCompletePage";
import CharacterLoadPage from "./pages/CharacterLoadPage";
import CharacterCreateCompletePage from "./pages/CharacterCreateCompletePage";
import CharacterActionsPage from "./pages/CharacterActionsPage";
import GamebookPage from "./pages/Gamebook/GamebookPage";
import Admin from "./pages/Admin/Admin";
import AdminPage from "./pages/Admin/AdminPage";
import AdminFlow from "./pages/Admin/AdminFlow";
import AdminAuthForm from "./pages/Admin/AdminAuthForm";

function App() {
	return (
		<Container className="App">
			<ModalCustom/>
			<BrowserRouter>
				<ReduxRedirect/>
				<Header/>
				<main>
					<TopContent/>
					<Routes>
						<Route path="/" element={<HomePage />}/>
						<Route path="/handbook" element={<HandbookPage />}/>
						<Route path="/character/register" element={<CharacterCreatePage />}/>
						<Route path="/character/register/complete" element={<CharacterCreateCompletePage />}/>
						<Route path="/character/load" element={<CharacterLoadPage />}/>
						<Route path="/character/actions" element={<CharacterActionsPage />}/>
						<Route path="/character/load/complete" element={<CharacterLoadCompletePage />}/>
						<Route path="/gamebook" element={<GamebookPage/>}/>
						<Route path="/gamebook/:pageNum" element={<GamebookPage/>}/>
						<Route path="/admin" element={<Admin/>}/>
						<Route path="/admin/auth" element={<AdminAuthForm/>}/>
						<Route path="/admin/flow" element={<AdminFlow/>}/>
						<Route path="/admin/page" element={<AdminPage/>}/>
						<Route path="/admin/page/:pageId" element={<AdminPage/>}/>
					</Routes>
				</main>
			</BrowserRouter>
			<footer>
				Copyright &copy; {new Date().getFullYear()}{" "}
				<a href="https://magicmediamuse.com" target="_blank" rel="noopener noreferrer">Richard Hung</a>.
				All Rights Reserved.
			</footer>
		</Container>
	);
}

export default App;
