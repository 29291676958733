"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Gamebooks API
 * A simple Express Library API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: richardhung111@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ItemEquippedToJSON = exports.ItemEquippedFromJSONTyped = exports.ItemEquippedFromJSON = void 0;
var runtime_1 = require("../runtime");
function ItemEquippedFromJSON(json) {
    return ItemEquippedFromJSONTyped(json, false);
}
exports.ItemEquippedFromJSON = ItemEquippedFromJSON;
function ItemEquippedFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'combatSkill': !(0, runtime_1.exists)(json, 'combatSkill') ? undefined : json['combatSkill'],
        'maxHealth': !(0, runtime_1.exists)(json, 'maxHealth') ? undefined : json['maxHealth'],
    };
}
exports.ItemEquippedFromJSONTyped = ItemEquippedFromJSONTyped;
function ItemEquippedToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'combatSkill': value.combatSkill,
        'maxHealth': value.maxHealth,
    };
}
exports.ItemEquippedToJSON = ItemEquippedToJSON;
