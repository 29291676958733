import React, {useCallback} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import common from "common";
import {Page} from "client";
import {loadCharacter} from "../../redux/character/CharacterActions";
import {Col, Row} from 'react-bootstrap';
import {combatRound} from "../../utils/characterEvents";

interface IProps {
	page: Page;
}

const GamebookCombat: React.FC<IProps> = (props: IProps): JSX.Element => {
	const {page} = props;
	const dispatch = useDispatch();
	const characterId = useSelector((state: IStore) => state.characterStore.characterId);
	const character = useSelector((state: IStore) => state.characterStore.character);
	const previousEvent = character.events.sort(common.utils.sortDocumentsDesc)[0];
	const calculatedCombatSkill = character.combatSkillCalculated + (page.combatSkill || 0);
	const combatLog = common.utils.combatProcessTurns(calculatedCombatSkill, page.enemies, character.events);

	const onCombatRound = useCallback(() => {
		(async () => {
			await combatRound(characterId);
			dispatch(loadCharacter(characterId));
		})();
	}, [dispatch, characterId]);

	if (!character.events.length) {
		return (<React.Fragment/>);
	}

	if (!page.enemies) {
		return (<div>There are no enemies on this page.</div>);
	}

	return (
		<div className="border-top border-dark py-3">
			<h4>Combat</h4>

			{(!combatLog.combatVictory) && (
				<button
					type="button"
					className="btn-theme"
					onClick={onCombatRound}
				>
					Attack Enemy
				</button>
			)}

			<Row className="py-3">
				<Col xs={6}>
					<h5>Player</h5>
					<div className="player-health">Health: {previousEvent.health} / {character.maxHealth}</div>
					<div className="player-combat">Calculated Combat Skill: {calculatedCombatSkill}</div>
				</Col>
				<Col xs={6}>
					<h5>Enemies</h5>
					{page.enemies.map((enemy, index) => (
						<div key={`enemy-${index}`}>
							<div><strong>{enemy.name}</strong></div>
							<div className="enemy-health">Max Health: {enemy.health}</div>
							<div className="enemy-combat">Combat Skill: {enemy.combatSkill}</div>
						</div>
					))}
				</Col>
			</Row>

			<div>
				<h5>Combat Log</h5>
				{combatLog.combatTurns.map((event, index) => (
					<div key={`turn-${index}`} className="py-2 border-bottom">
						<div>You roll a {event.diceResult}.</div>
						<div>
							<span>You take {event.damageToPlayer} damage. </span>
							{(event.playerHealth > 0) ?
								(<span>You have {event.playerHealth} health left.</span>) :
								(<span>You are dead.</span>)}
						</div>
						<div>
							<span>{event.enemyName} takes {event.damageToEnemy} damage. </span>
							{(event.enemyHealth > 0) ?
								(<span>{event.enemyName} has {event.enemyHealth} health left.</span>) :
								(<span>{event.enemyName} is dead.</span>)}
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default GamebookCombat;
