import {CharacterEvent} from "client";
import {sortDocumentsDesc} from "./sortDocuments.utils";

/**
 * Chunk of events that have happened on the current page. Used for combat log, dice roll validation, & other preprocess functions.
 * @param events
 */
export function latestPageEventChunk(events: Array<CharacterEvent>): CharacterEvent[] {
	const eventsSort = (events || []).sort(sortDocumentsDesc);
	const newArray: Array<CharacterEvent> = [];
	let pageId = "";

	// Since it may be possible to loop back to a previous page, we need to manually mark a break point
	let isContinue = true;

	eventsSort.forEach((event) => {
		const eventPageId = event.page;

		// Set the initial page num to be compared against
		if (!pageId) {
			pageId = eventPageId;
		}
		if (eventPageId.toString() !== pageId.toString()) {
			isContinue = false;
		}
		if (isContinue) {
			newArray.push(event);
		}
	});

	return newArray;

}

