import InitialMetaStore, {IMetaStore} from "./meta/InitialMetaStore";
import InitialModalCustomStore, {IModalCustomStore} from "./modalCustom/InitialModalCustomStore";
import InitialCharacterStore, {ICharacterStore} from "./character/InitialCharacterStore";

export interface IStore {
	metaStore: IMetaStore;
	modalCustomStore: IModalCustomStore;
	characterStore: ICharacterStore;
}

export default {
	metaStore: InitialMetaStore,
	modalCustomStore: InitialModalCustomStore,
	characterStore: InitialCharacterStore,
} as IStore;
