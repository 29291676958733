import {ReactNode} from "react";

export interface IModalCustomStore {
	title?: string;
	body: ReactNode;
	redirectOnClose?: string;
	modalShow?: boolean;
	refreshOnClose?: boolean;
	className?: string;
	buttonLeft?: {
		text: string;
		callback: () => void;
	};
}

export default {
	title: "",
	body: "",
	className: "",
	modalShow: false,
	redirectOnClose: undefined,
	refreshOnClose: false,
	buttonLeft: undefined,
} as IModalCustomStore;
