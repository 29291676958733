/**
 * Generate a random integer number
 * @param min
 * @param max
 */
export default function diceRoll(min = 1, max = 6): number {

	// Create the pool from min to max
	// Math.random will generate a number from 0 to LESS THAN 1
	// Increase the number pool's max by one in order to actually get
	// a chance to reach the max when we round down with Math.floor
	const pool = max - min + 1;

	// Generate a random number from the pool
	const rand = Math.floor(Math.random() * pool);

	// Add the random number from the pool back to the minimum number
	return rand + min;
}

