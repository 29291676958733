import {characterType} from "./CharacterReducer";
import {CharactersApi, CharacterCreateBody} from "client";
import {createAsyncThunk} from '@reduxjs/toolkit';
import {IStore} from "../defaultStore";

export const newCharacter = createAsyncThunk(characterType.NEW_CHARACTER, async (characterCreateBody: CharacterCreateBody) => {
	const response = await new CharactersApi().createCharacter({characterCreateBody});
	return {
		character: response,
	};
});

export const loadCharacter = createAsyncThunk(characterType.LOAD_CHARACTER, async (characterId: string) => {
	const response = await new CharactersApi().getCharacter({
		id: characterId,
	});
	return {
		character: response,
	};
});

export const rehydrateAuth = createAsyncThunk(characterType.REHYDRATE_AUTH, async (arg, thunkAPI) => {
	const characterId: string = (thunkAPI.getState() as IStore).characterStore.characterId;
	if (characterId) {
		const response = await new CharactersApi().getCharacter({id: characterId});
		return {
			character: response,
		};
	} else {
		return thunkAPI.rejectWithValue("rehydrateAuth Failed");
	}
});

export function logout() {
	return {type: characterType.LOGOUT};
}

