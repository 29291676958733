import React, {useCallback} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {ECharacterEventType, Page} from "client";
import {loadCharacter} from "../../redux/character/CharacterActions";
import {pageDiceRoll} from "../../utils/characterEvents";

interface IProps {
	page: Page;
}

const GamebookDiceRoll: React.FC<IProps> = (props: IProps): JSX.Element => {
	const {page} = props;
	const dispatch = useDispatch();
	const characterId = useSelector((state: IStore) => state.characterStore.characterId);
	const character = useSelector((state: IStore) => state.characterStore.character);
	const eventDiceRoll = character.events.find((event) => event.type === ECharacterEventType.DICE_ROLL);

	const onDiceRoll = useCallback(() => {
		(async () => {
			await pageDiceRoll(characterId);
			dispatch(loadCharacter(characterId));
		})();
	}, [dispatch, characterId]);

	if (!page || !page.diceRoll) {
		return (<React.Fragment/>);
	}

	if (eventDiceRoll) {
		return (
			<div className="py-3">You rolled a {eventDiceRoll.diceResult}</div>
		);
	} else {
		return (
			<div className="py-3">
				<button
					type="button"
					className="btn-theme"
					onClick={onDiceRoll}
				>
					Roll Dice
				</button>
			</div>
		);
	}
};

export default GamebookDiceRoll;
