import React, {useCallback, useEffect, useState} from 'react';
import {Field, FormRenderProps} from "react-final-form";
import Input from "../../components/form/Input";
import SingleCheckbox from "../../components/form/SingleCheckbox";
import {Card, Col, Row} from "react-bootstrap";
import {Page, Item, Skill, SkillsApi, PageChoice} from "client";
import {FieldArray, FieldArrayRenderProps} from "react-final-form-arrays";
import {OnChange} from 'react-final-form-listeners';

interface IProps extends FormRenderProps {
	pages: Array<Page>;
	items: Array<Item>;
}

const AdminPageFormRenderChoices: React.FC<IProps> = (props: IProps): JSX.Element => {
	const {pages, items, form} = props;
	const [skills, setSkills] = useState<Array<Skill>>([]);

	useEffect(() => {
		let mounted = true;
		(async () => {
			const response = await new SkillsApi().getAllSkills();
			if (response && mounted) {
				setSkills(response);
			}
		})();
		return () => {
			mounted = false;
		}
	}, []);

	const renderHasItems = useCallback((choiceValue: PageChoice, name: string) => {
		if (!choiceValue?.hasItems) return [];
		return Object.keys(choiceValue.hasItems).map((itemId, index) => {
			const item = items.find((element) => itemId === element.id);
			if (!item) return (<React.Fragment key={`item-${index}`}/>);
			return (
				<Row key={item.id} className="mb-3">
					<Col xs={7} className="d-flex align-items-center">
						{item.title} Required Quantity
					</Col>
					<Col xs={3} className="d-flex align-items-center">
						<Field
							name={`${name}.hasItems.${itemId}`}
							component={Input}
							type="number"
							required={true}
							min={1}
						/>
					</Col>
					<Col xs={2} className="d-flex align-items-center">
						<button
							className="btn btn-danger w-100"
							onClick={() => {
								const newItemList: { [key: string]: number } = {...choiceValue.hasItems};
								delete newItemList[itemId];
								form.change(`${name}.hasItems`, newItemList);
							}}
						>
							<span className="far fa-times"/>
						</button>
					</Col>
				</Row>
			);
		})
	}, [form, items]);

	const renderUpdateItems = useCallback((choiceValue: PageChoice, name: string) => {
		if (!choiceValue?.updateItems) return [];
		return Object.keys(choiceValue.updateItems).map((itemId, index) => {
			const item = items.find((element) => itemId === element.id);
			if (!item) return (<React.Fragment key={`item-${index}`}/>);
			return (
				<Row key={item.id} className="mb-3">
					<Col xs={7} className="d-flex align-items-center">
						{item.title} Update Quantity
					</Col>
					<Col xs={3} className="d-flex align-items-center">
						<Field
							name={`${name}.updateItems.${itemId}`}
							component={Input}
							type="number"
							required={true}
						/>
					</Col>
					<Col xs={2} className="d-flex align-items-center">
						<button
							className="btn btn-danger w-100"
							onClick={() => {
								const newItemList: { [key: string]: number } = {...choiceValue.updateItems};
								delete newItemList[itemId];
								form.change(`${name}.updateItems`, newItemList);
							}}
						>
							<span className="far fa-times"/>
						</button>
					</Col>
				</Row>
			);
		})
	}, [form, items]);


	const renderPageChoiceArray = useCallback((fields: FieldArrayRenderProps<PageChoice, any>["fields"]) => {
		return fields.map((name, index) => {
			const choiceValue: PageChoice = fields.value[index];
			const page: Page = pages.find((element) => {
				try {
					return element.id === choiceValue.pageTarget;
				} catch {
					return false;
				}
			});
			return (
				<Col key={name} xs={4} className="mb-3">
					<OnChange name={`${name}.newItemHasId`}>
						{(value) => {
							if (value) {
								const newItemList: { [key: string]: number } = {...choiceValue.hasItems};
								newItemList[value] = 1;
								form.change(`${name}.hasItems`, newItemList);
								form.change(`${name}.newItemHasId`, "");
							}
						}}
					</OnChange>
					<OnChange name={`${name}.newItemUpdateId`}>
						{(value) => {
							if (value) {
								const newItemList: { [key: string]: number } = {...choiceValue.updateItems};
								newItemList[value] = 1;
								form.change(`${name}.updateItems`, newItemList);
								form.change(`${name}.newItemUpdateId`, "");
							}
						}}
					</OnChange>
					<Card>
						<Card.Body>
							{/*<Card.Title>Page {page?.pageNum}</Card.Title>*/}
							<Row className="pb-3 border-bottom" title={page?.body}>
								<Col xs={8}>
									<Field
										placeholder="Choice Prefix Text"
										name={`${name}.title`}
										component={Input}
										type="text"
										required={true}
									/>
								</Col>
								<Col xs={4}>
									<Field
										className="form-control"
										name={`${name}.pageTarget`}
										component="select"
										required={true}
									>
										<option value="">#</option>
										{pages.sort((a, b) => a.pageNum - b.pageNum).map((page) => (
											<option key={page.id} value={page.id}>
												{page.pageNum} {page.title}
											</option>
										))}
									</Field>
								</Col>
							</Row>
							<Row className="py-3 border-bottom">
								<Col
									xs={6}
									title="Choosing this option effects character health. Positive numbers heal and negative numbers damage."
								>
									<Field
										label="Health update"
										name={`${name}.health`}
										component={Input}
										type="number"
									/>
								</Col>
								<Col
									xs={6}
									title="Choosing this option effects gold amount. Positive numbers receive and negative numbers remove."
								>
									<Field
										label="Gold update"
										name={`${name}.gold`}
										component={Input}
										type="number"
									/>
								</Col>
							</Row>
							<div className="py-3 border-bottom">
								<Field
									label="This choice requires combat victory."
									name={`${name}.combat`}
									component={SingleCheckbox}
									type="checkbox"
								/>
							</div>
							<Row className="py-3 border-bottom">
								<Col xs={6}>
									<div>Skills required</div>
									<Field
										className="form-control"
										name={`${name}.skills`}
										component="select"
										multiple={true}
									>
										{skills.map((skill) => (
											<option key={skill.id} value={skill.id}>
												{skill.title}
											</option>
										))}
									</Field>
								</Col>
								<Col xs={6}>
									<div>Dice Results</div>
									<Field
										className="form-control"
										name={`${name}.diceResult`}
										component="select"
										multiple={true}
									>
										<option value="1">1</option>
										<option value="2">2</option>
										<option value="3">3</option>
										<option value="4">4</option>
										<option value="5">5</option>
										<option value="6">6</option>
									</Field>
								</Col>
							</Row>
							<div
								className="py-3 border-bottom"
								title="Positive quantity numbers only. Checks that the item is in the character's inventory, but does not update the inventory."
							>
								{renderHasItems(choiceValue, name)}
								<Field
									className="form-control bg-primary text-white"
									name={`${name}.newItemHasId`}
									component="select"
								>
									<option value="">Choice requires another item in inventory</option>
									{items.sort((a, b) => a.title.localeCompare(b.title)).map((item) => (
										<option key={item.id} value={item.id}>{item.title}</option>
									))}
								</Field>
							</div>
							<div
								className="py-3 border-bottom"
								title="Positive numbers add the item and negative numbers remove. When removing, it's 'up to X quantity'. Add the same item in the 'required quantity' with an inverted quantity to do a check before updating."
							>
								{renderUpdateItems(choiceValue, name)}
								<Field
									className="form-control bg-primary text-white"
									name={`${name}.newItemUpdateId`}
									component="select"
								>
									<option value="">Choosing this choice updates another item in inventory</option>
									{items.sort((a, b) => a.title.localeCompare(b.title)).map((item) => (
										<option key={item.id} value={item.id}>{item.title}</option>
									))}
								</Field>
							</div>
							<button
								className="btn btn-danger w-100"
								type="button"
								onClick={() => fields.remove(index)}
							>
								Delete this choice
							</button>
						</Card.Body>
					</Card>
				</Col>
			)
		});

	}, [form, items, pages, renderHasItems, renderUpdateItems, skills]);

	return (
		<FieldArray name="choices">
			{({fields}) => (
				<div>
					<h4>Choices</h4>

					{/*{JSON.stringify(fields.value)}*/}
					<Row className="mb-3">
						{renderPageChoiceArray(fields)}
					</Row>
					<button
						type="button"
						className="btn btn-primary"
						onClick={() => fields.push({pageNum: 0})}
					>
						Add Another Choice
					</button>
				</div>
			)}
		</FieldArray>
	);
};

export default AdminPageFormRenderChoices;
