"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Gamebooks API
 * A simple Express Library API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: richardhung111@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CharacterToJSON = exports.CharacterFromJSONTyped = exports.CharacterFromJSON = void 0;
var runtime_1 = require("../runtime");
var CharacterEvent_1 = require("./CharacterEvent");
var ItemWithQuantity_1 = require("./ItemWithQuantity");
var Skill_1 = require("./Skill");
function CharacterFromJSON(json) {
    return CharacterFromJSONTyped(json, false);
}
exports.CharacterFromJSON = CharacterFromJSON;
function CharacterFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'gold': json['gold'],
        'items': !(0, runtime_1.exists)(json, 'items') ? undefined : (json['items'].map(ItemWithQuantity_1.ItemWithQuantityFromJSON)),
        'skills': !(0, runtime_1.exists)(json, 'skills') ? undefined : (json['skills'].map(Skill_1.SkillFromJSON)),
        'events': !(0, runtime_1.exists)(json, 'events') ? undefined : (json['events'].map(CharacterEvent_1.CharacterEventFromJSON)),
        'combatSkill': json['combatSkill'],
        'combatSkillCalculated': !(0, runtime_1.exists)(json, 'combatSkillCalculated') ? undefined : json['combatSkillCalculated'],
        'maxHealth': json['maxHealth'],
        'maxHealthCalculated': !(0, runtime_1.exists)(json, 'maxHealthCalculated') ? undefined : json['maxHealthCalculated'],
    };
}
exports.CharacterFromJSONTyped = CharacterFromJSONTyped;
function CharacterToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'gold': value.gold,
        'items': value.items === undefined ? undefined : (value.items.map(ItemWithQuantity_1.ItemWithQuantityToJSON)),
        'skills': value.skills === undefined ? undefined : (value.skills.map(Skill_1.SkillToJSON)),
        'events': value.events === undefined ? undefined : (value.events.map(CharacterEvent_1.CharacterEventToJSON)),
        'combatSkill': value.combatSkill,
        'combatSkillCalculated': value.combatSkillCalculated,
        'maxHealth': value.maxHealth,
        'maxHealthCalculated': value.maxHealthCalculated,
    };
}
exports.CharacterToJSON = CharacterToJSON;
