import React, {useCallback} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import common from "common";
import {Page} from "client";
import {loadCharacter, logout} from "../../redux/character/CharacterActions";
import {nextPage} from "../../utils/characterEvents";

interface IProps {
	page: Page;
}

const GamebookChoices: React.FC<IProps> = (props: IProps): JSX.Element => {
	const {page} = props;
	const dispatch = useDispatch();
	const characterId = useSelector((state: IStore) => state.characterStore.characterId);
	const character = useSelector((state: IStore) => state.characterStore.character);

	const onNextPage = useCallback((choiceId: string) => {
		(async () => {
			await nextPage(choiceId, characterId);
			dispatch(loadCharacter(characterId));
		})();
	}, [dispatch, characterId]);


	if (!character.events.length) {
		return (<React.Fragment/>);
	}

	if (character.events.sort(common.utils.sortDocumentsDesc)[0].health <= 0) {
		return (
			<div className="border-top border-dark py-3">
				<span>You are dead. </span>
				<button className="btn-link" onClick={() => dispatch(logout())}>
					Delete character and retry?
				</button>
			</div>
		);
	}

	if (!page.choices.length) {
		return (
			<div className="border-top border-dark py-3">
				<span>The End. </span>
				<button className="btn-link" onClick={() => dispatch(logout())}>
					Delete character and try a different path?
				</button>
			</div>
		);
	}

	return (
		<div className="border-top border-dark pt-3">
			{page.choices.map((choice) => (
				<div key={choice.id} className="mb-3">
					<button
						type="button"
						className="btn-link"
						onClick={() => onNextPage(choice.id)}
						disabled={!common.utils.pageChoiceValidation(choice, page, character)}
					>
						{choice.title} {choice.pageTargetNum}
					</button>
				</div>
			))}
		</div>
	);
};

export default GamebookChoices;
