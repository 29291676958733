import {ECharacterEventType, ItemCategory, Page, Character, CharacterEvent} from "client";
import {latestPageEventChunk} from "./latestPageEventChunk.utils";

/**
 * Check if character is allowed to pick up item.
 * @param itemId ID of item to be picked up
 * @param itemCategory Category of item to be picked up
 * @param page Latest page for character
 * @param character
 */
export function itemCheckAcquirable(itemId: string, itemCategory: ItemCategory, page: Page, character: Character): { success: boolean; error?: string; } {
	if (!itemId || !itemCategory || !page || !character || !character.events) {
		return {success: false};
	}

	const itemAcquireEvents: CharacterEvent[] = latestPageEventChunk(character.events)
		.filter((event) => event.type === ECharacterEventType.ITEM_ACQUIRE);
	const itemAcquired = itemAcquireEvents.find((event) => event.entity.toString() === itemId);

	const pageItem = page.items?.find(i => i.id === itemId);
	if (!pageItem) {
		return {success: false, error: "Item does not exist on this page."};
	}

	if (itemAcquired) {
		return {success: false, error: "Item has already been picked up."};
	}

	if (itemCategory.unique) {
		if (character.items?.find(i => i.id === itemId)) {
			return {success: false, error: "You already have this item."};
		}
	}

	if (itemCategory.limit) {
		let count = 0;
		const itemQuantity = pageItem.quantity;
		// Loop through items in character's current inventory
		for (const inventoryItem of character.items) {
			// Check that item is in the same category
			if (inventoryItem.category.id === itemCategory.id) {
				// Add to existing count
				count = count + inventoryItem.quantity;
			}
		}
		// Check that new quantity does not exceed limit
		if (count + itemQuantity > itemCategory.limit) {
			return {success: false, error: `Maximum ${itemCategory.limit} ${itemCategory.title} items in inventory.`};
		}
	}

	return {success: true};
}

/**
 * HTML inputs, even "number" type, always return as string. Run it through a parseInt function to always type number.
 * @param oldObject
 * @return {[itemId: string] => quantity}
 */
export function objectItemQuantity(oldObject: { [itemId: string]: number | string }): { [itemId: string]: number } {
	const newObject: { [itemId: string]: number } = {};

	for (const itemId in oldObject) {
		newObject[itemId] = parseInt(oldObject[itemId].toString());
	}

	return newObject;

}
